import axios from './axios';

export enum LookupItemType {
  Country = 'country',
  City = 'city',
  State = 'state',
  Profession = 'profession',
  SpeakerLanguage = 'speaker_lang',
  Field = 'field',
  Speciality = 'speciality',
}

export interface LookupItem {
  _id: number;
  id: string;
  txtAr: string;
  txtEn: string;
  parentId: string;
  type: LookupItemType;
}

const cache = {};

export const createLookUpService = async data => {
  try {
    const res = await axios.post(`lookups`, data);
    return {
      data: res.data,
    };
  } catch (error) {
    return {
      error,
    };
  }
};

export const getLookups = ({
  type,
  parentId,
  disableCache,
  pageSize = 10000,
}: {
  type: LookupItemType;
  parentId?: string;
  disableCache?: any;
  pageSize?: number;
}) => {
  const params = { type, ...(parentId && { parentId }), pageSize };
  const key = JSON.stringify(params);
  if (!disableCache && cache[key]) {
    return cache[key];
  }
  const lookups = axios.get(`lookups`, { params }).then(res => res.data);
  cache[key] = lookups;
  return lookups;
};
