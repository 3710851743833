/* eslint-disable no-console */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as hotelReservationsAPI from 'api/hotels-reservations';
import dayjs from 'dayjs';
import { NotificationType } from 'environment/constants';
import { showNotification } from 'util/show-notification';

const hotelReservationsAdapter = createEntityAdapter({
  selectId: hotel => hotel._id,
});

const errors = {
  get: 'Unable to get hotels reservations!',

  search: 'Unable to search hotels!',
};

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        title: '',
        tax: 0,
        breakfast: true,
        description: '',
        wifi: true,
        image: '',
        images: [],
        fees: [
          {
            name: 'Contribution to tourism',
            value: 0,
            perType: 'perPersonPerNight',
          },
        ],
        location: {
          country: '',
          city: '',
          state: '',
          lat: null,
          lng: null,
        },
      },
      visible: false,
      editing: false,
    },
  },
  filterQuery: {},
  filteredHotels: [],
};

const initialState = hotelReservationsAdapter.getInitialState(defaultState);

export const prepareHotelReservationsSearch = query => {
  const newQuery = { ...query };
  if (!newQuery?.bookStatus) delete newQuery.bookStatus;
  if (!newQuery?._id) delete newQuery._id;
  if (!newQuery?.email) delete newQuery.email;
  if (!newQuery?.hotel) delete newQuery.hotel;
  if (!newQuery?.startDate) delete newQuery.startDate;
  if (!newQuery?.endDate) delete newQuery.endDate;
  return newQuery;
};
export const refreshHotelReservations = createAsyncThunk(
  'reservations/refresh',
  async (_, { getState, rejectWithValue }) => {
    const { query } = getState().hotelsReservations;
    try {
      const newQuery = prepareHotelReservationsSearch(query);
      const res = await hotelReservationsAPI.getHotelReservations(newQuery);
      const newRes = {
        totalCount: Number(res.totalCount),
        results: res.reservations,
      };
      return newRes;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response?.data?.message) || errors.get;
    }
  },
);

const hotelReservationsSlice = createSlice({
  name: 'hotelsReservations',
  initialState,
  reducers: {
    openCreateHotelReservationsModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateHotelReservationsModal(state) {
      return {
        ...state,
        modals: defaultState.modals,
      };
    },
    openEditHotelReservationsModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: payload,
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchHotelModalReservationsData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
  },
  extraReducers: {
    [refreshHotelReservations.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshHotelReservations.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...hotelReservationsAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshHotelReservations.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
  },
});

export const hotelsReservationsSelectors = hotelReservationsAdapter.getSelectors(state => state.hotelsReservations);

export const {
  patchHotelModalReservationsData,
  openCreateHotelReservationsModal,
  closeCreateHotelReservationsModal,
  openEditHotelReservationsModal,
  openHotelCpnAndDiscModal,
  closeHotelCpnAndDiscModal,
} = hotelReservationsSlice.actions;

export default hotelReservationsSlice.reducer;
