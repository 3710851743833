import config from 'environment/config';
import axios from './axios';

export interface CareersApplicants {
  _id: string;
  slug: string;
  title: string;
  shortDescription: string;
  description: string;
  country: string;
  state: string;
  city: string;
  responsibilities: string[];
  requirements: string[];
  open: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CareersApplicantsQuery {
  searchText?: string;
  pageNumber: number;
  open?: boolean;
}

export const getCareers = (query: CareersApplicantsQuery = { pageNumber: 1 }): Promise<CareersApplicants[]> => {
  return axios
    .get('/careers/jobApp', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);
};

export const deleteCareer = id => axios.delete(`/careers/jobApp/${id}`).then(res => res.data);

export const editCareer = ({ _id, ...payload }) => {
  return axios.put(`/careers/jobApp/${_id}/status`, payload).then(res => ({ _id, ...payload }));
};
