import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ListEventBookingsQuery } from 'graph/booked-tickets/booked-tickets.query';
import { graphQLClient } from 'graph/GraphQLClient';

const bookedTicketAdapter = createEntityAdapter({
  selectId: bookedTicket => bookedTicket._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
};

const initialState = bookedTicketAdapter.getInitialState(defaultState);

export const refreshBookedTickets = createAsyncThunk('booked-tickets/refresh', async (_, { getState }) => {
  const { query } = getState().bookedTickets;
  const result = await graphQLClient.query({
    query: ListEventBookingsQuery,
    variables: {
      ...query,
    },
    fetchResults: true,
  });
  return result.data.listEventBookings;
});

const bookedTicketsSlice = createSlice({
  name: 'bookedTickets',
  initialState,
  extraReducers: {
    [refreshBookedTickets.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshBookedTickets.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...bookedTicketAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshBookedTickets.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
  },
});

export const bookedTicketsSelectors = bookedTicketAdapter.getSelectors(state => state.bookedTickets);

export default bookedTicketsSlice.reducer;
