import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as businessPagesAPI from 'api/businessPages';
import config from 'environment/config';
import { showNotification } from 'util/show-notification';
import { NotificationType } from 'environment/constants';
import { graphQLClient } from 'graph/GraphQLClient';
import { ListBusinessPagesQuery } from 'graph/business-pages/business-page.query';
import i18n from '../../i18n';

const businessAdapter = createEntityAdapter({
  selectId: businessPage => businessPage._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        rootUserId: '',
        name: '',
        email: '',
        field: '',
        phoneNumber: undefined,
        websiteUrl: '',
        about: '',
        image: {},
        location: {
          country: '',
          state: '',
          city: '',
          area: '',
          lat: config.map.center.lat,
          lng: config.map.center.lng,
          addressGoogle: '',
          address: '',
        },
        license: [],
        socialLinks: {
          facebook: '',
          twitter: '',
          linkedin: '',
        },
      },
      visible: false,
      editing: false,
    },
  },
};

const initialState = businessAdapter.getInitialState(defaultState);

export const deleteBusinessPage = createAsyncThunk('businessPages/delete', async (id, { rejectWithValue }) => {
  try {
    return await businessPagesAPI.deleteBusinessPage(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('business-pages:errors:delete');
  }
});

export const createBusinessPage = createAsyncThunk('businessPages/create', async (_, { getState, rejectWithValue }) => {
  try {
    const payload = getState().businessPages.modals.create.data;
    const { lat, lng, address } = payload.location;
    return await businessPagesAPI.createBusinessPage({
      ...payload,
      location: { lat, lng, address },
    });
  } catch (err) {
    console.log('Err', err);
    return rejectWithValue(err.response?.data?.error) || i18n.t('business-pages:errors:create');
  }
});

export const generateFilterPayloadRequest = payload => {
  if (payload.license) {
    payload =
      payload.license.length === 2 || payload.license.length === 0
        ? { ...payload, license: undefined }
        : { ...payload, license: payload.license[0] === 'yes' };
  }
  if (payload.query) {
    payload = {
      ...payload,
      query: String(payload.query)
        .replaceAll(')', '\\)')
        .replaceAll('(', '\\(')
        .replaceAll('[', '\\[')
        .replaceAll(']', '\\]')
        .trim(),
    };
  }

  return payload;
};
export const refreshBusinessPages = createAsyncThunk('businessPages/refresh', async (_, { getState }) => {
  const { query } = getState().businessPages;
  const result = await graphQLClient.query({
    query: ListBusinessPagesQuery,
    variables: {
      ...generateFilterPayloadRequest(query),
    },
    fetchResults: true,
  });
  return result.data.searchBusinessPage;
});

export const fetchMoreBusinessPages = createAsyncThunk('businessPages/fetchMore', async (_, { getState }) => {
  const state = getState().businessPages;
  const businessPages = await businessPagesAPI.getBusinessPages(state.query);
  return businessPages;
});

export const setBusinessPageStatus = createAsyncThunk('businessPages/setStatus', ({ _id, status }) => {
  return businessPagesAPI.setBusinessPageStatus(_id, status);
});

export const setBusinessPageLimited = createAsyncThunk('businessPages/edit', async (payload, { rejectWithValue }) => {
  try {
    return await businessPagesAPI.editBusinessPage(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('business-pages:errors:edit');
  }
});

export const editBusinessPage = createAsyncThunk('businessPages/edit', async (_, { getState, rejectWithValue }) => {
  try {
    const payload = getState().businessPages.modals.create.data;
    return await businessPagesAPI.editBusinessPage(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('business-pages:errors:edit');
  }
});

const stripBusinessPageLocation = ({ country, city, area, state, online, ...location }) => {
  return location;
};

const stripBusinessPageForEdit = payload => {
  const { __v, admins, createdAt, updatedAt, status, slug, isBusinessPage, imageUrl, ...data } = payload;
  return {
    ...data,
    location: stripBusinessPageLocation(data.location),
  };
};

const businessPagesSlice = createSlice({
  name: 'businessPages',
  initialState,
  reducers: {
    openCreateBusinessPageModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateBusinessPageModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            visible: false,
          },
        },
      };
    },
    openEditBusinessPageModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: stripBusinessPageForEdit({
              ...payload,
              limited: typeof payload?.limited === 'boolean' ? payload?.limited : true,
            }),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchBusinessPageModalData(state, { payload }) {
      state.modals = {
        ...state.modals,
        create: { ...state.modals.create, data: { ...state.modals.create.data, ...payload } },
      };
    },
  },
  extraReducers: {
    [refreshBusinessPages.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshBusinessPages.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...businessAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshBusinessPages.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [fetchMoreBusinessPages.pending.type]: state => {
      const { length } = businessAdapter.getSelectors().selectAll(state);
      return {
        ...state,
        ...(state.totalCount > length && { query: { ...state.query, pageNumber: state.query.pageNumber + 1 } }),
        loading: true,
        error: null,
      };
    },
    [fetchMoreBusinessPages.fulfilled.type]: (state, { payload: { result, totalCount } }) => {
      return {
        ...businessAdapter.addMany({ ...state }, result),
        loading: false,
        totalCount,
      };
    },
    [fetchMoreBusinessPages.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [setBusinessPageStatus.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setBusinessPageStatus.fulfilled.type]: (state, { payload }) => {
      return {
        ...state,
        ...businessAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [setBusinessPageStatus.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [createBusinessPage.pending.type]: state => {
      state = { ...state, ...businessAdapter.removeAll(state), loading: true, error: null };
    },
    [createBusinessPage.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Congratulations!',
        `Business page ${payload.name.substr(0, 25)} created successfully`,
      );
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
    [createBusinessPage.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
    [editBusinessPage.pending.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [editBusinessPage.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Done!',
        `Business page ${payload.name.substr(0, 25)} edited successfully`,
      );
      return {
        ...state,
        ...businessAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editBusinessPage.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },

    [deleteBusinessPage.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteBusinessPage.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Done!', `Business page Deleted successfully`);
      return {
        ...state,
        ...businessAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteBusinessPage.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
  },
});

export const businessPagesSelectors = businessAdapter.getSelectors(state => state.businessPages);

export const {
  patchBusinessPageModalData,
  openCreateBusinessPageModal,
  closeCreateBusinessPageModal,
  openEditBusinessPageModal,
} = businessPagesSlice.actions;

export default businessPagesSlice.reducer;
