import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import LoadingPage from 'pages/Loading/LoadingPage';
import Routes from './Routes';

export default function RoutesWrapper() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Router>
        <Routes />
      </Router>
    </Suspense>
  );
}
