import axios from './axios';

export const changeOrderStatus = payload => axios.post('vc/callback', payload);
export const exportOrdersService = async (filters: any) => {
  // eslint-disable-next-line no-return-await
  return axios.post(
    `events/orders/export`,
    { filters },
    { responseType: 'arraybuffer', timeout: 100 * 60 * 60 * 1000 },
  );
};
