import { configureStore } from '@reduxjs/toolkit';
import bookedTicketsReducer from './bookedTickets/bookedTicketsSlice';
import businessPagesReducer from './businessPages/businessPagesSlice';
import speakersReducer from './speakers/speakersSlice';
import eventsReducer from './events/eventsSlice';
import hotelsReducer from './hotels/hotelsSlice';
import hotelsRoomsReducer from './hotels-rooms/hotelsRoomsSlice';
import hotelReservationsReducer from './hotels-reservations/hotelsReservationsSlice';
import eventSummariesReducer from './eventSummaries/eventSummariesSlice';
import lookupsReducer from './lookups/lookupsSlice';
import contactRequestsReducer from './contact-requests/contactRequestsSlice';
import ordersReducer from './orders/ordersSlice';
import transactionsReducer from './transactions/transactionsSlice';
import authReducer from './users/authSlice';
import usersReducer from './users/usersSlice';
import adsReducer from './ads/adsSlice';
import investorsReducer from './investors/investorsSlice';
import careersSlice from './careers/careersSlice';
import careersApplicantsSlice from './careersApplicants/careersApplicantsSlice';

const reducer = {
  auth: authReducer,
  users: usersReducer,
  businessPages: businessPagesReducer,
  speakers: speakersReducer,
  investors: investorsReducer,
  events: eventsReducer,
  careers: careersSlice,
  careersApplicants: careersApplicantsSlice,
  hotels: hotelsReducer,
  hotelsRooms: hotelsRoomsReducer,
  hotelsReservations: hotelReservationsReducer,
  lookups: lookupsReducer,
  bookedTickets: bookedTicketsReducer,
  eventSummaries: eventSummariesReducer,
  transactions: transactionsReducer,
  orders: ordersReducer,
  contactRequests: contactRequestsReducer,
  ads: adsReducer,
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
