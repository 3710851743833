import gql from 'graphql-tag';

export const EventSummaryFragment = gql`
  fragment eventSummary on EventSummaryEntity {
    _id
    event {
      _id
      title
      status
      tickets {
        name
        currency
        _id
      }
    }
    businessPage {
      _id
      name
    }
    status
    totalAmount
    transactionsSum
    transactionsCount
    lastTransactionDate
    ticketSummaries {
      price
      qty
      totalCoupon
      totalDiscount
      totalProcessingFees
      totalNet
      totalGrossSales
      totalNetSales
      totalDebitCoatConnectFees
      totalCreditCoatConnectCouponFees
      totalCreditCoatConnectDiscountFees
      totalNetRated
      ticketId
    }
  }
`;

export const GetEventSummaryQuery = gql`
  query eventSummary($eventId: String!) {
    eventSummary(id: $eventId) {
      ...eventSummary
    }
  }
  ${EventSummaryFragment}
`;

export const ListEventSummariesQuery = gql`
  query eventSummaries(
    $eventId: String
    $businessPageId: String
    $pageSize: Float
    $pageNumber: Float
    $showFree: Boolean
    $status: EventSummaryStatus
  ) {
    eventSummaries(
      eventId: $eventId
      businessPageId: $businessPageId
      pageSize: $pageSize
      pageNumber: $pageNumber
      status: $status
      showFree: $showFree
    ) {
      results {
        ...eventSummary
      }
      totalCount
    }
  }
  ${EventSummaryFragment}
`;
