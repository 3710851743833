import { ApolloProvider } from '@apollo/react-hooks';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import './i18n';
import { graphQLClient } from 'graph/GraphQLClient';
import countries from 'i18n-iso-countries';
import enCountries from 'i18n-iso-countries/langs/en.json';
import RoutesWrapper from './RoutesWrapper';
import store from './store';

function App() {
  // initialize country translation
  useEffect(() => {
    countries.registerLocale(enCountries);
  }, []);

  return (
    <Provider store={store}>
      <ApolloProvider client={graphQLClient}>
        <RoutesWrapper />
      </ApolloProvider>
    </Provider>
  );
}

export default hot(App);
