import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ContactRequestType, ListContactRequests, SetContactRequestResolved } from 'api/contact-requests';

const contactRequestsAdapter = createEntityAdapter({
  selectId: contactRequest => contactRequest._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    type: ContactRequestType.Contact,
    pageNumber: 1,
  },
};

const initialState = contactRequestsAdapter.getInitialState(defaultState);

export const refreshContactRequests = createAsyncThunk('contact-requests/refresh', async (_, { getState }) => {
  const { query } = getState().contactRequests;
  return ListContactRequests(query.type, query.pageNumber);
});

export const setContactRequestResolved = createAsyncThunk('contact-requests/set-resolved', async ({ id, resolved }) => {
  return SetContactRequestResolved(id, resolved);
});

const contactRequestSlice = createSlice({
  name: 'contactRequests',
  initialState,
  extraReducers: {
    [refreshContactRequests.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshContactRequests.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...contactRequestsAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshContactRequests.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [setContactRequestResolved.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setContactRequestResolved.fulfilled.type]: (state, { payload }) => {
      return {
        ...state,
        ...contactRequestsAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [setContactRequestResolved.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
  },
});

export const contactRequestSelectors = contactRequestsAdapter.getSelectors(state => state.contactRequests);

export default contactRequestSlice.reducer;
