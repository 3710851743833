import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ListEventSummariesQuery } from 'graph/event-summaries/event-summaries.query';
import { graphQLClient } from 'graph/GraphQLClient';
import { cleanObject } from 'util/remove-falsy';

const eventSummaryAdapter = createEntityAdapter({
  selectId: eventSummary => eventSummary.event?._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
};

const initialState = eventSummaryAdapter.getInitialState(defaultState);

export const refreshEventSummaries = createAsyncThunk('event-summaries/refresh', async (_, { getState }) => {
  const { query } = getState().eventSummaries;
  const result = await graphQLClient.query({
    query: ListEventSummariesQuery,
    variables: cleanObject(query),
    fetchResults: true,
  });
  return result.data.eventSummaries;
});

const eventSummarySlice = createSlice({
  name: 'eventSummaries',
  initialState,
  extraReducers: {
    [refreshEventSummaries.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshEventSummaries.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...eventSummaryAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshEventSummaries.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
  },
});

export const eventSummariesSelectors = eventSummaryAdapter.getSelectors(state => state.eventSummaries);

export default eventSummarySlice.reducer;
