import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { graphQLClient } from 'graph/GraphQLClient';
import * as ordersApi from 'api/orders';
import { showNotification } from 'util/show-notification';
import { NotificationType } from 'environment/constants';
import { ORDERS_QUERY } from '../../graph/orders/orders.query';

const ordersAdapter = createEntityAdapter({
  selectId: order => order._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
};

const initialState = ordersAdapter.getInitialState(defaultState);

export const getOrders = async query => {
  const result = await graphQLClient.query({
    query: ORDERS_QUERY,
    variables: {
      ...query,
    },
    fetchResults: true,
  });

  if (result.data.orders?.results) {
    result.data.orders.results = result.data.orders?.results?.map(order => {
      if (order.orderStatus === 'pending') {
        if (moment(new Date(order.expiresIn)).isBefore(new Date())) {
          order.orderStatus = 'expired';
        }
      }
      return order;
    });
  }

  return result.data.orders;
};

export const refreshOrders = createAsyncThunk('orders/refresh', async (_, { getState }) => {
  const { query } = getState().orders;
  return getOrders(query);
});

export const changeOrderStatus = createAsyncThunk('orders/status', async (payload, { rejectWithValue }) => {
  try {
    return ordersApi.changeOrderStatus(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || 'failed to change order status';
  }
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  extraReducers: {
    [refreshOrders.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshOrders.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...ordersAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshOrders.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [changeOrderStatus.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [changeOrderStatus.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Success!', `Order processed Successfully`);
      const payloadData = JSON.parse(payload.config.data);
      return {
        ...state,
        ...ordersAdapter.updateOne(
          { ...state },
          { id: payloadData.order_id, changes: { orderStatus: payloadData.success ? 'success' : 'declined' } },
        ),
        loading: false,
        error: null,
      };
    },
    [changeOrderStatus.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload?.toString() || 'Something went wrong!');
      return { ...state, loading: false, error: null };
    },
  },
});

export const ordersSelectors = ordersAdapter.getSelectors(state => {
  return state.orders;
});

export default ordersSlice.reducer;
