import axios from './axios';

export const getAllUsers = () => axios.get('users');

export const getUsers = email => axios.get('users', { params: { email, pageSize: 10 } }).then(res => res.data.result);

export const getDefaultUsers = () => axios.get('users', { params: { pageSize: 10 } }).then(res => res.data.result);

export const deleteUser = id => axios.delete(`users/${id}`).then(res => res.data);

export const verifyUser = id => axios.put(`users/${id}/verify`).then(res => res.data);

export const healthcareVerifyUser = (id, status) =>
  axios.put(`users/${id}/verifyhelthcare`, { verified: status }).then(res => res.data);
