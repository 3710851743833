import gql from 'graphql-tag';

export const BusinessPageEntityFragment = gql`
  fragment businessPage on BusinessPageEntity {
    _id
    name
    email
    rootUserId
    phoneNumber
    websiteUrl
    field
    about
    socialLinks {
      facebook
      twitter
      linkedin
    }
    image {
      card
      cover
      thump
    }
    location {
      address
      lat
      lng
    }
    license
    status
    limited
    createdAt
    updatedAt
  }
`;

export const BusinessPageQuery = gql`
  query businessPage($id: String!) {
    businessPage(id: $id) {
      _id
      name
    }
  }
`;

export const ListBusinessPagesQuery = gql`
  query searchBusinessPage(
    $pageSize: Float
    $pageNumber: Float
    $query: String
    $status: [BusinessPageStatus!]
    $license: Boolean
    $date: [DateTime!]
    $sort: BusinessPageSortObject
    $limited: String
  ) {
    searchBusinessPage(
      pageSize: $pageSize
      pageNumber: $pageNumber
      limited: $limited
      query: $query
      status: $status
      license: $license
      date: $date
      sort: $sort
    ) {
      results {
        ...businessPage
      }
      totalCount
    }
  }
  ${BusinessPageEntityFragment}
`;
