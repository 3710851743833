/* eslint-disable no-console */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as hotelsAPI from 'api/hotels';
import dayjs from 'dayjs';
import { NotificationType } from 'environment/constants';
import { showNotification } from 'util/show-notification';

const hotelAdapter = createEntityAdapter({
  selectId: hotel => hotel._id,
});

const errors = {
  get: 'Unable to get hotels!',
  create: 'Unable to create new hotel!',
  edit: 'Unable to edit the hotel!',
  search: 'Unable to search hotels!',
  delete: 'Unable to delete hotel!',
};

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  isExternal: false,
  modals: {
    create: {
      data: {
        title: '',
        tax: 0,
        breakfast: true,
        description: '',
        wifi: true,
        image: '',
        images: [],
        fees: [
          {
            name: 'Contribution to tourism',
            value: 0,
            perType: 'perPersonPerNight',
          },
        ],
        location: {
          country: '',
          city: '',
          state: '',
          lat: null,
          lng: null,
        },
      },
      visible: false,
      editing: false,
    },
  },
  filterQuery: {},
  filteredHotels: [],
};

const initialState = hotelAdapter.getInitialState(defaultState);

const hasStates = country => ['United States', 'Canada', 'Australia'].includes(country);

const stripHotelForSubmit = payload => {
  let newPayload = { ...payload };

  if (newPayload.location) {
    newPayload = { ...newPayload, ...newPayload.location };
    if (newPayload?.state === '' || !hasStates(newPayload?.country)) delete newPayload.state;
    delete newPayload.location;
  }
  if (newPayload?.images?.length <= 0) delete newPayload.images;
  if (newPayload?.addressGoogle) delete newPayload.addressGoogle;
  if (newPayload?.externalLink) {
    delete newPayload.tax;
    delete newPayload.wifi;
    delete newPayload.breakfast;
    delete newPayload.fees;
  }
  return {
    ...newPayload,
  };
};

export const createHotel = createAsyncThunk('hotels/create', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().hotels.modals.create.data;
    payload = stripHotelForSubmit(payload);

    if (payload?.images?.length <= 0) delete payload.images;
    return await hotelsAPI.createHotel(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.create;
  }
});

export const editHotel = createAsyncThunk('hotels/edit', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().hotels.modals.create.data;
    payload = stripHotelForSubmit(payload);

    return await hotelsAPI.editHotel(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.edit;
  }
});

export const deleteHotel = createAsyncThunk('hotels/delete', async (id, { rejectWithValue }) => {
  try {
    return await hotelsAPI.deleteHotel(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.delete;
  }
});

export const refreshHotels = createAsyncThunk('hotels/refresh', async (_, { getState, rejectWithValue }) => {
  const { query } = getState().hotels;
  const newQuery = { ...query };
  try {
    if (!newQuery?.country) delete newQuery.country;
    if (!newQuery?.city) delete newQuery.city;
    if (!newQuery?.state) delete newQuery.state;
    if (!newQuery?.text) delete newQuery.text;
    if (!newQuery?._id) delete newQuery._id;
    const res = await hotelsAPI.getHotels(newQuery);
    const newRes = {
      totalCount: Number(res.totalCount),
      results: res.hotels,
    };
    return newRes;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response?.data?.error) || errors.get;
  }
});

const stripHotelForEdit = payload => {
  const { __v, createdAt, updatedAt, country, city, state, lat, lng, slug, images, allowedEvents, ...data } = payload;
  const newData = { ...data };
  const location = {};
  if (country) {
    location.country = country;
  }
  if (state) {
    location.state = state;
  }
  if (city) {
    location.city = city;
  }
  if (lat) {
    location.lat = Number(lat);
  }
  if (lng) {
    location.lng = Number(lng);
  }
  newData.images = images
    ? images.map(img => {
        delete img._id;
        return img;
      })
    : [];

  newData.location = location;
  newData.fees = newData.fees.map(fee => {
    delete fee._id;
    return fee;
  });
  return {
    ...newData,
    ...(allowedEvents && { allowedEvents: allowedEvents.map(e => e._id) }),
  };
};

const hotelsSlice = createSlice({
  name: 'hotels',
  initialState,
  reducers: {
    setIsExternal(state, { payload }) {
      return {
        ...state,
        isExternal: payload !== null ? payload : !state.isExternal,
      };
    },
    openCreateHotelModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateHotelModal(state) {
      return {
        ...state,
        modals: defaultState.modals,
      };
    },
    openEditHotelModal(state, { payload }) {
      return {
        ...state,
        isExternal: payload?.externalLink && payload?.externalLink?.length > 0,
        modals: {
          ...state.modals,
          create: {
            data: stripHotelForEdit(payload),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchHotelModalData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
    openHotelCpnAndDiscModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          couponsAndDisc: {
            hotelId: payload?._id,
            visible: true,
          },
        },
      };
    },
    closeHotelCpnAndDiscModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          couponsAndDisc: {
            visible: false,
            hotelId: null,
          },
        },
      };
    },
  },
  extraReducers: {
    [refreshHotels.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshHotels.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...hotelAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshHotels.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    // #endregion
    [deleteHotel.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteHotel.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Success!', `Hotel Deleted Successfully`);
      return {
        ...state,
        ...hotelAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteHotel.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },
    [createHotel.pending.type]: state => ({ ...state, loading: true, error: null }),
    [createHotel.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Congratulations!',
        `Hotel ${payload.title.substr(0, 25)} added successfully`,
      );
      return { ...state, loading: false, error: null };
    },
    [createHotel.rejected.type]: (state, action) => {
      console.log(action);
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },
    [editHotel.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [editHotel.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Done!', `Hotel ${payload.title.substr(0, 25)} edited successfully`);
      return {
        ...state,
        ...hotelAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editHotel.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },
  },
});

export const hotelsSelectors = hotelAdapter.getSelectors(state => state.hotels);

export const {
  patchHotelModalData,
  openCreateHotelModal,
  closeCreateHotelModal,
  openEditHotelModal,
  openHotelCpnAndDiscModal,
  closeHotelCpnAndDiscModal,
  setIsExternal,
} = hotelsSlice.actions;

export default hotelsSlice.reducer;
