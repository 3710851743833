import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { graphQLClient } from 'graph/GraphQLClient';
import { UsersQuery } from 'graph/users/user.query';
import * as usersApi from 'api/users';
import i18n from 'i18n';
import { showNotification } from 'util/show-notification';
import { NotificationType } from 'environment/constants';

const usersAdapter = createEntityAdapter({
  selectId: user => user._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
};

const initialState = usersAdapter.getInitialState(defaultState);

export const refreshUsers = createAsyncThunk('users/fetchAll', async (_, { getState }) => {
  const { query } = getState().users;

  // if (query.speciality && query.speciality.length > 0) {
  //   const { field, ...rest } = query;
  //   query = rest;
  // }

  const result = await graphQLClient.query({
    query: UsersQuery,
    variables: {
      ...query,
    },
    fetchResults: true,
  });
  return result.data.users;
});

export const deleteUser = createAsyncThunk('users/delete', async (id, { rejectWithValue }) => {
  try {
    return await usersApi.deleteUser(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('users:errors:delete');
  }
});

export const verifyUser = createAsyncThunk('users/verify', async (id, { rejectWithValue }) => {
  try {
    return await usersApi.verifyUser(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('users:errors:verify');
  }
});

export const healthcareVerifyUser = createAsyncThunk(
  'users/verifyhelthcare',
  async ({ id, status }, { rejectWithValue }) => {
    try {
      return await usersApi.healthcareVerifyUser(id, status);
    } catch (err) {
      return rejectWithValue(err.response?.data?.error) || i18n.t('users:errors:healthcare-verify');
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: {
    [refreshUsers.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshUsers.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...usersAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshUsers.rejected]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [deleteUser.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteUser.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Success!', `User Deleted Successfully`);
      return {
        ...state,
        ...usersAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteUser.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
    [verifyUser.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [verifyUser.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Success!', `User Verified Successfully`);
      return {
        ...state,
        ...usersAdapter.updateOne(
          { ...state },
          { id: payload._id, changes: { ...payload, isVerified: payload.isVerified } },
        ),
        loading: false,
        error: null,
      };
    },
    [verifyUser.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
    [healthcareVerifyUser.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [healthcareVerifyUser.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Success!', `User Verified Status Changed Successfully`);
      return {
        ...state,
        ...usersAdapter.updateOne(
          { ...state },
          { id: payload._id, changes: { ...payload, isHealthCareVerified: payload.isHealthCareVerified } },
        ),
        loading: false,
        error: null,
      };
    },
    [healthcareVerifyUser.rejected.type]: (state, action) => {
      showNotification(
        NotificationType.Error,
        'Error!',
        action?.payload?.toString() || i18n.t('users:errors:healthcare-verify'),
      );
      return { ...state, loading: false, error: null };
    },
  },
});

export const usersSelectors = usersAdapter.getSelectors(state => state.users);

export default usersSlice.reducer;
