/* eslint-disable no-console */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as careersApi from 'api/careers';
import { NotificationType } from 'environment/constants';
import { showNotification } from 'util/show-notification';

const careersAdapter = createEntityAdapter({
  selectId: career => career._id,
});

const errors = {
  get: 'Unable to get careers!',
  create: 'Unable to create new career!',
  edit: 'Unable to edit the career!',
  search: 'Unable to search careers!',
  delete: 'Unable to delete career!',
};

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        title: '',
        shortDescription: '',
        description: '',
        country: '',
        state: null,
        city: '',
        responsibilities: null,
        requirements: null,
        open: true,
        jobType: 'on-site',
      },
      visible: false,
      editing: false,
    },
  },
  filterQuery: {},
  filteredHotels: [],
};

const initialState = careersAdapter.getInitialState(defaultState);

const stripeCareerForSubmit = payload => {
  const newPayload = { ...payload };
  if (!newPayload?.responsibilities || newPayload?.responsibilities?.length <= 0) delete newPayload.responsibilities;
  if (!newPayload?.requirements || newPayload?.requirements?.length <= 0) delete newPayload.requirements;
  if (newPayload.jobType === 'remotely') {
    if (newPayload?.location) delete newPayload.location;
    if (newPayload?.country) delete newPayload.country;
    if (newPayload?.state) delete newPayload.state;
    if (newPayload?.city) delete newPayload.city;
  }
  if (newPayload?.location) {
    if (newPayload?.location?.country) newPayload.country = newPayload.location.country;
    if (newPayload?.location?.state) newPayload.state = newPayload.location.state;
    if (newPayload?.location?.city) newPayload.city = newPayload.location.city;
    delete newPayload.location;
  }
  if (!newPayload.state) delete newPayload.state;
  return {
    ...newPayload,
  };
};

export const createCareer = createAsyncThunk('careers/create', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().careers.modals.create.data;
    payload = stripeCareerForSubmit(payload);

    // if (payload?.images?.length <= 0) delete payload.images;
    return await careersApi.createCareer(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.create;
  }
});

export const editCareer = createAsyncThunk('careers/edit', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().careers.modals.create.data;
    payload = stripeCareerForSubmit(payload);

    return await careersApi.editCareer(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.edit;
  }
});

export const deleteCareer = createAsyncThunk('careers/delete', async (id, { rejectWithValue }) => {
  try {
    return await careersApi.deleteCareer(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.delete;
  }
});

export const refreshCareers = createAsyncThunk('careers/refresh', async (_, { getState, rejectWithValue }) => {
  const { query } = getState().careers;
  const newQuery = { ...query };
  try {
    if (!newQuery?.text) delete newQuery.text;
    if (!newQuery?._id) delete newQuery._id;
    if (!newQuery?.jobType) delete newQuery.jobType;
    const res = await careersApi.getCareers(newQuery);
    const newRes = {
      totalCount: Number(res.totalCount),
      results: res.jobs,
    };
    return newRes;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response?.data?.message) || errors.get;
  }
});

const stripCareerForEdit = payload => {
  const newPayload = { ...payload };
  if (newPayload?.country) newPayload.location = { country: newPayload.country };
  if (newPayload?.state) newPayload.location.state = newPayload.state;
  if (newPayload?.city) newPayload.location.city = newPayload.city;

  return newPayload;
};

const careersSlice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
    openCreateCareerModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    changeJobType(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              jobType: payload,
            },
          },
        },
      };
    },
    closeCreateCareerModal(state) {
      return {
        ...state,
        modals: defaultState.modals,
      };
    },
    openEditCareerModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: stripCareerForEdit(payload),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchCareerModalData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
  },
  extraReducers: {
    [refreshCareers.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshCareers.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...careersAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshCareers.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [deleteCareer.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteCareer.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Success!', `Career Deleted Successfully`);
      return {
        ...state,
        ...careersAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteCareer.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },
    [createCareer.pending.type]: state => ({ ...state, loading: true, error: null }),
    [createCareer.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Congratulations!',
        `Career ${payload.title.substr(0, 25)} added successfully`,
      );
      return { ...state, loading: false, error: null };
    },
    [createCareer.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },
    [editCareer.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [editCareer.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Done!', `Career ${payload.title.substr(0, 25)} edited successfully`);
      return {
        ...state,
        ...careersAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editCareer.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);

      return { ...state, loading: false, error: null };
    },
  },
});

export const careersSelectors = careersAdapter.getSelectors(state => state.careers);

export const {
  patchCareerModalData,
  openCreateCareerModal,
  closeCreateCareerModal,
  openEditCareerModal,
  changeJobType,
} = careersSlice.actions;

export default careersSlice.reducer;
