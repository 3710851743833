import config from 'environment/config';
import axiosTest from 'axios';
import axios from './axios';

export enum EventStatus {
  Pending = 'Pending',
  Published = 'Published',
  Suspended = 'Suspended',
  Draft = 'Draft',
  Closed = 'Closed',
  Declined = 'Declined',
}

// export enum EventType {
//   RecordedCourse = 'recorded',
//   WebConference = 'live',
//   Workshop = 'offline',
//   LiveCourse = 'live_course',
//   OfflineCourse = 'offline_course',
//   MedicalConference = 'medical_conference',
// }

export enum EventType {
  RecordedCourse = 'recorded',
  WebConference = 'live',
  LiveCourse = 'live_course',
  Workshop = 'offline',
  OfflineCourse = 'offline_course',
  MedicalConference = 'medical_conference',
}

export const EventTypesMap: any = {
  [EventType.RecordedCourse]: 'recorded-courses',
  [EventType.WebConference]: 'web-conferences',
  [EventType.Workshop]: 'medical-workshops',
  [EventType.LiveCourse]: 'live-courses',
  [EventType.MedicalConference]: 'medical-conferences',
  [EventType.OfflineCourse]: 'medical-courses',
};

export function isEventOnline(type) {
  return [EventType.WebConference, EventType.LiveCourse, EventType.RecordedCourse].includes(type);
}

export function isEventOffline(type) {
  return [EventType.Workshop, EventType.MedicalConference, EventType.OfflineCourse].includes(type);
}

export enum RefundPolicy {
  NoRefund = 1,
  EventStart = 2,
  DayBeforeEventStart = 3,
  WeekBeforeEventStart = 4,
  MonthBeforeEventStart = 5,
}

export enum RefundPolicyMapped {
  'No refund' = 1,
  'Refund is available until the start day of the event' = 2,
  'Refund is available until 1 day before the event' = 3,
  'Refund is available until 1 week before the event' = 4,
  'Refund is available until 1 month before the event' = 5,
}

export interface Event {
  _id: string;
  name: string;
  status: EventStatus;
  email: string;
  createdAt: Date;
  updatedAt: Date;
  field: string;
}

export interface EventsQuery {
  searchText?: string;
  pageNumber: number;
  status?: string[];
}

export interface Coupon {
  _id?: string;
  name: string;
  qty: number;
  percentage: number;
  amount: number;
  startDate: Date;
  endDate: Date;
  tickets: string[];
}

export const getEvents = (query: EventsQuery = { pageNumber: 1 }): Promise<Event[]> =>
  axios
    .get('events', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);

export const getEvent = (id: string) => axios.get(`events/${id}/show`).then(res => res.data);

export const setEventStatus = (id: string, status: EventStatus): Promise<Event> =>
  axios.put(`events/${id}/status`, { status }).then(res => res.data);

export const createEvent = payload => axios.post('events', payload).then(res => res.data);

export const editEvent = ({ _id, ...payload }) => axios.put(`events/${_id}`, payload).then(res => res.data);

export const deleteEvent = id => axios.delete(`events/${id}`).then(res => res.data);

export const checkEvent = async ({ name, id = null }) => {
  let payload = {};
  if (id) {
    payload = {
      id,
      name,
    };
  } else {
    payload = {
      name,
    };
  }
  try {
    await axios.post('events/check_name', payload);
    return true;
  } catch (e) {
    return false;
  }
};

export const getEventActiveTicketsCount = eventId =>
  axios.get(`events/${eventId}/tickets/count`).then(res => res.data.count);

export const getEventCoupons = (id: string) =>
  axios.get(`events/${id}/coupons?type=coupon`).then(res => ({ coupons: res.data, eventId: id }));

export const getEventDiscounts = (id: string) =>
  axios.get(`events/${id}/coupons?type=discount`).then(res => ({ discounts: res.data, eventId: id }));

export const createCoupon = (eventId: string, payload: Coupon) =>
  axios.post(`events/${eventId}/coupons`, payload).then(res => res.data);

export const updateCoupon = (eventId: string, payload: Coupon) =>
  axios.put(`events/${eventId}/coupons/${payload._id}`, payload);

export const setVerify = (id: string, featured: boolean): Promise<Event> =>
  axios.put(`events/${id}/featured`, { featured }).then(res => res.data);

export const setPremium = (id: string, premium: boolean): Promise<Event> =>
  axios.put(`events/${id}/premium`, { premium }).then(res => res.data);

export const setScraped = (id: string, scraped: boolean): Promise<Event> =>
  axios.put(`events/${id}/scraped`, { scraped }).then(res => res.data);

export const exportEventsService = async (filters: any) => {
  // eslint-disable-next-line no-return-await
  return axios.post(`events/export`, { filters }, { responseType: 'arraybuffer', timeout: 100 * 60 * 60 * 1000 });
};
