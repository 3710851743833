/* eslint-disable no-console */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as hotelsRoomsAPI from 'api/hotels-rooms';
import dayjs from 'dayjs';
import { NotificationType } from 'environment/constants';
import { showNotification } from 'util/show-notification';

const hotelRoomAdapter = createEntityAdapter({
  selectId: room => room._id,
});

const errors = {
  get: 'Unable to get hotel rooms!',
  create: 'Unable to create new hotel room!',
  edit: 'Unable to edit the hotel room!',
  search: 'Unable to search hotel rooms!',
  delete: 'Unable to delete hotel room!',
};

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        currency: 'USD',
        title: '',
        description: '',
        hotel: '',
        allowedEvents: [],
        quantity: 0,
        location: {
          country: '',
          city: '',
          state: '',
        },
        roomType: '',
        capacityPrice: [
          {
            capacity: 'SINGLE',
            price: 0,
          },
        ],
        image: '',
        images: [],
      },
      visible: false,
      editing: false,
    },
  },
  filterQuery: {},
  filteredHotels: [],
};

const initialState = hotelRoomAdapter.getInitialState(defaultState);

const stripHotelRoomForSubmit = payload => {
  const newPayload = { ...payload };
  if (newPayload?.location) delete newPayload.location;

  if (newPayload?.images?.length <= 0) delete newPayload.images;
  if (newPayload?.addressGoogle) delete newPayload.addressGoogle;
  newPayload.roomType = newPayload.title;
  return {
    ...newPayload,
  };
};

export const createHotelRoom = createAsyncThunk('hotelsRooms/create', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().hotelsRooms.modals.create.data;
    payload = stripHotelRoomForSubmit(payload);

    if (payload?.images?.length <= 0) delete payload.images;
    return await hotelsRoomsAPI.createHotelRooms(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.message) || errors.create;
  }
});

export const editHotelRoom = createAsyncThunk('hotelsRooms/edit', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().hotelsRooms.modals.create.data;
    payload = stripHotelRoomForSubmit(payload);

    return await hotelsRoomsAPI.editHotelRooms(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.message) || errors.edit;
  }
});

export const deleteHotelRoom = createAsyncThunk('hotelsRooms/delete', async (id, { rejectWithValue }) => {
  try {
    return await hotelsRoomsAPI.deleteHotelRooms(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.message) || errors.delete;
  }
});

export const refreshHotelsRooms = createAsyncThunk('hotelsRooms/refresh', async (_, { getState, rejectWithValue }) => {
  const { query } = getState().hotelsRooms;
  const newQuery = { ...query };
  try {
    if (!newQuery?.country) delete newQuery.country;
    if (!newQuery?.city) delete newQuery.city;
    if (!newQuery?.state) delete newQuery.state;
    if (!newQuery?.text) delete newQuery.text;
    if (!newQuery?._id) delete newQuery._id;
    const res = await hotelsRoomsAPI.getHotelsRooms(newQuery);
    const newRes = {
      totalCount: Number(res.totalCount),
      results: res.rooms,
    };
    return newRes;
  } catch (err) {
    return rejectWithValue(err.response?.data?.message) || errors.get;
  }
});

const stripHotelRoomForEdit = payload => {
  const {
    __v,
    createdAt,
    updatedAt,
    country,
    city,
    state,
    slug,
    hotel,
    allowedEvents,
    capacityPrice,
    images,
    ...data
  } = payload;
  const newData = { ...data };
  const location = {};
  if (country) {
    location.country = country;
  }
  if (state) {
    location.state = state;
  }
  if (city) {
    location.city = city;
  }
  if (hotel?._id) newData.hotel = hotel._id;
  newData.images = images
    ? images.map(img => {
        delete img._id;
        return img;
      })
    : [];
  newData.location = location;
  newData.allowedEvents = allowedEvents.map(i => i._id);
  newData.capacityPrice = capacityPrice.map(i => {
    delete i._id;
    return i;
  });
  return {
    ...newData,
  };
};

const hotelsRoomsSlice = createSlice({
  name: 'hotelsRooms',
  initialState,
  reducers: {
    openCreateHotelRoomsModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateHotelRoomsModal(state) {
      return {
        ...state,
        modals: defaultState.modals,
      };
    },
    openEditHotelRoomsModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: stripHotelRoomForEdit(payload),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchHotelRoomsModalData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
  },
  extraReducers: {
    [refreshHotelsRooms.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshHotelsRooms.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...hotelRoomAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshHotelsRooms.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    // #endregion
    [deleteHotelRoom.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteHotelRoom.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Success!', `Hotel Deleted Successfully`);
      return {
        ...state,
        ...hotelRoomAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteHotelRoom.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload.toString());
      return { ...state, loading: false, error: null };
    },
    [createHotelRoom.pending.type]: state => ({ ...state, loading: true, error: null }),
    [createHotelRoom.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Congratulations!',
        `Hotel ${payload.title.substr(0, 25)} added successfully`,
      );
      return { ...state, loading: false, error: null };
    },
    [createHotelRoom.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload.map(error => error).toString());
      return { ...state, loading: false, error: null };
    },
    [editHotelRoom.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [editHotelRoom.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Done!', `Hotel ${payload.title.substr(0, 25)} edited successfully`);
      return {
        ...state,
        ...hotelRoomAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editHotelRoom.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload.map(error => error).toString());
      return { ...state, loading: false, error: null };
    },
  },
});

export const hotelsRoomsSelectors = hotelRoomAdapter.getSelectors(state => state.hotelsRooms);

export const {
  patchHotelRoomsModalData,
  openCreateHotelRoomsModal,
  closeCreateHotelRoomsModal,
  openEditHotelRoomsModal,
  openHotelRoomsCpnAndDiscModal,
  closeHotelCpnAndDiscModal,
} = hotelsRoomsSlice.actions;

export default hotelsRoomsSlice.reducer;
