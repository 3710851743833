import { EventType } from 'api/events';

const config = {
  auth: {
    localStorageKey: 'token',
    loginEndpoint: 'employees/login',
    refreshTokenEndpoint: 'employees/token',
  },
  pageSize: 10,
  validation: {
    phone: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    url: '^((?!^(http|https|://|htt://)).)*$',
    aboutHTML: '<p><br></p>',
  },
  map: {
    center: {
      lat: 30.0444,
      lng: 31.2357,
    },
    zoom: 15,
    height: '180px',
  },
  modals: {
    width: '80%',
    formsLayout: {
      // labelAlign: 'right',
      // labelCol: { span: 8 },
      // wrapperCol: { span: 16 },
      labelAlign: 'left',
      labelCol: { span: 5, offset: 1 },
      wrapperCol: { span: 18 },
    },
  },
  currencies: ['USD', 'EGP', 'COP'],
  roomTypes: ['SINGLE', 'DOUBLE', 'TRIPLE', 'QUADRUPLE'],
  eventTypes: Object.values(EventType),
  dateFormat: 'MM/DD/YYYY hh:mm a',
  imageExtensions: ['jpg', 'jpeg', 'png'],
};

export default config;
