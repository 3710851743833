import gql from 'graphql-tag';

export const EventEntityFragment = gql`
  fragment event on EventEntity {
    _id
    agendaURL
    isTimeHidden
    title
    email
    ranking
    bussinessPageId
    businessPage {
      _id
      name
    }
    isFreeOnlineEvent
    type
    speakerLang
    description
    # profession
    field
    speciality
    tags
    eventTime {
      startDate
      endDate
    }
    tickets {
      _id
      name
      qty
      price
      currency
      description
      startDate
      endDate
      suspended
    }
    refundPolicyId
    serviceFees
    image {
      card
      cover
      thump
    }
    location {
      country
      city
      state
      area
      address
      lat
      lng
    }
    bookLink
    meetingRoom
    status
    slug
    type
    isFreeOnlineEvent
    faq {
      question
      answer
    }
    gallery
    speakers {
      _id
      name
      bio
      job
      field
      speciality
      country
      state
      city
      image {
        cover
        thump
        card
      }
    }
    pendingSpeakers {
      _id
      name
      bio
      job
      field
      speciality
      country
      state
      city
      image {
        cover
        thump
        card
      }
    }
    instructors {
      name
      bio
      job
      field
      speciality
      country
      state
      city
      image {
        cover
        thump
        card
      }
    }
    eventContents {
      topic {
        name
        url
      }
      lectures {
        name
        url
        duration
      }
    }
    skills
    featured
    createdAt
    updatedAt
    meta {
      title
      description
      canonical
    }
  }
`;

export const ListEventsQuery = gql`
  query search(
    $country: String
    $city: String
    $query: String
    $_id: String
    $title: String
    # $profession: [String!]
    $field: [String!]
    $speciality: [String!]
    $status: [EventStatus!]
    $type: [EventType!]
    $date: [DateTime!]
    $pageSize: Float
    $pageNumber: Float
    $sort: EventSortObject
    $businessPages: [String!]
    $featured: Boolean
    $premium: Boolean
    $scraped: Boolean
  ) {
    search(
      country: $country
      city: $city
      query: $query
      _id: $_id
      title: $title
      # profession: $profession
      field: $field
      speciality: $speciality
      date: $date
      status: $status
      type: $type
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
      businessPages: $businessPages
      featured: $featured
      premium: $premium
      scraped: $scraped
    ) {
      results {
        ...event
        scraped
        viewsCount
        impressionsCount
        clicksCount
        premium
      }
      totalCount
    }
  }
  ${EventEntityFragment}
`;

export const SelectEventQuery = gql`
  query event($id: String!) {
    event(eventId: $id) {
      ...event
    }
  }
  ${EventEntityFragment}
`;
