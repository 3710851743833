import { notification } from 'antd';
import axios, { AxiosInstance } from 'axios';
import environment from 'environment/config';
import { getToken, refreshToken, removeToken } from './auth';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(req => {
  // inject token into each request
  req.headers.authorization = `Bearer ${getToken()}`;
  return req;
});

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    // fetch token from local storage
    const token = getToken();

    // show notification of a connection error
    if (error.message === 'Network Error') {
      notification.error({
        message: 'Unable to connect to servers.',
      });
    }

    // thorw error
    if (error.response.status !== 403 || !token) {
      return Promise.reject(error);
    }

    // remove token if request is unauthorized
    if (error.config.url === environment.auth.refreshTokenEndpoint) {
      removeToken();
      return Promise.reject(error);
    }

    // retry request after refreshing token
    try {
      const data = await refreshToken();
      const { config } = error;
      config.headers.authorization = `Bearer ${data}`;
      return axios.request(config);
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export default axiosInstance;
