import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ListInvestorsRequests, DeleteInvestorRequest, SetInvestorRequestResolved } from 'api/investors';

const investorsAdapter = createEntityAdapter({
  selectId: inv => inv._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
};

const initialState = investorsAdapter.getInitialState(defaultState);

export const refreshInvestorsRequests = createAsyncThunk('investors/refresh', async (_, { getState }) => {
  const { query } = getState().investors;
  return ListInvestorsRequests(query.pageNumber);
});

export const investorRequestDelete = createAsyncThunk('investors/delete', async ({ id }) => {
  return DeleteInvestorRequest(id);
});

export const setInvestorRequestResolved = createAsyncThunk('investors/set-resolved', async ({ id, status }) => {
  return SetInvestorRequestResolved(id, status);
});

const investorsRequestsSlice = createSlice({
  name: 'investors',
  initialState,
  extraReducers: {
    [refreshInvestorsRequests.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshInvestorsRequests.fulfilled.type]: (state, { payload: { data, count } }) => {
      return {
        ...investorsAdapter.setAll({ ...state }, data),
        loading: false,
        totalCount: count,
      };
    },
    [refreshInvestorsRequests.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [investorRequestDelete.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [investorRequestDelete.fulfilled.type]: (state, { payload }) => {
      return {
        ...state,
        ...investorsAdapter.removeOne({ ...state }, payload._id),
        loading: false,
        error: null,
      };
    },
    [investorRequestDelete.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },

    [setInvestorRequestResolved.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setInvestorRequestResolved.fulfilled.type]: (state, { payload }) => {
      return {
        ...state,
        ...investorsAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [setInvestorRequestResolved.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
  },
});

export const investorsRequestSelectors = investorsAdapter.getSelectors(state => state.investors);

export default investorsRequestsSlice.reducer;
