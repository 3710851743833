import gql from 'graphql-tag';

export const ListTransactionsQuery = gql`
  query transactions($eventId: String) {
    transactions(eventId: $eventId) {
      results {
        _id
        eventId
        event {
          _id
          title
          status
        }
        amount
        pictureUrl
        createdAt
      }
      totalCount
    }
  }
`;
