import { notification } from 'antd';
import { NotificationType } from 'environment/constants';

export const showNotification = (type, message, description) => {
  const args = {
    message,
    description,
  };
  console.log(type, message, description);
  switch (type) {
    case NotificationType.Success:
      return notification.success(args);
    case NotificationType.Error:
      return notification.error(args);
    case NotificationType.Info:
      return notification.info(args);
    default:
      return null;
  }
};
