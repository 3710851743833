import gql from 'graphql-tag';

export const UserEntityFragment = gql`
  fragment user on UserEntity {
    _id
    userName
    email
    isVerified
    field
    speciality
    mobileNumber
    country
    healthCareVerificationImageUrl
    isHealthCareVerified
    createdAt
    detectedCountry
    socialProvider {
      ID
      name
      updatedProfile
    }
  }
`;

export const UserQuery = gql`
  query user($id: String!) {
    user(id: $id) {
      _id
      firstName
      lastName
      email
    }
  }
`;

export const UsersQuery = gql`
  query users(
    $pageSize: Float
    $pageNumber: Float
    $query: String
    $isHealthCareVerified: String
    $isVerified: String
    # $profession: [String!]
    $field: [String!]
    $speciality: [String!]
    $country: [String!]
    $date: [DateTime!]
    $registerType: [String!]
    $sort: UsersSortObject
  ) {
    users(
      query: $query
      isHealthCareVerified: $isHealthCareVerified
      isVerified: $isVerified
      # profession: $profession
      field: $field
      speciality: $speciality
      country: $country
      date: $date
      registerType: $registerType
      pageSize: $pageSize
      pageNumber: $pageNumber
      sort: $sort
    ) {
      results {
        ...user
      }
      totalCount
    }
  }
  ${UserEntityFragment}
`;
