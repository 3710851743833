import axios from './axios';

export const ListInvestorsRequests = (page = 0) => {
  return axios.get('/investors/request', { params: { page } }).then(res => res.data);
};

export const DeleteInvestorRequest = _id => {
  return axios.delete(`/investors/request/${_id}`).then(res => res.data);
};

export const SetInvestorRequestResolved = (_id, status) => {
  return axios.put(`/investors/request/${_id}/status`, { status }).then(res => res.data);
};
