import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as speakersAPI from 'api/speakers';
import config from 'environment/config';
import { showNotification } from 'util/show-notification';
import { NotificationType } from 'environment/constants';
import { graphQLClient } from 'graph/GraphQLClient';
import i18n from '../../i18n';

const speakerAdapter = createEntityAdapter({
  selectId: speaker => speaker._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        name: '',
        job: '',
        field: '',
        speciality: [],
        image: {},
        bio: '',
        country: '',
        state: '',
        city: '',
      },
      visible: false,
      editing: false,
    },
  },
};

const initialState = speakerAdapter.getInitialState(defaultState);

export const deleteSpeaker = createAsyncThunk('speaker/delete', async (id, { rejectWithValue }) => {
  try {
    return await speakersAPI.deleteSpeaker(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || "Couldn't delete speaker";
  }
});

const hasStates = country => ['United States', 'Canada', 'Australia'].includes(country);

const stripSpeakerForSubmit = payload => {
  const newPayload = { ...payload };

  if (newPayload.location) {
    if (newPayload.location.country) newPayload.country = newPayload.location.country;
    if (newPayload.location.state) newPayload.state = newPayload.location.state;
    if (newPayload.location.city) newPayload.city = newPayload.location.city;
    delete newPayload.location;
  } else delete newPayload.location;

  if (newPayload.country === '' || newPayload.country === null) delete newPayload.country;
  if (newPayload.city === '' || newPayload.city === null) delete newPayload.city;
  if (newPayload.state === '' || newPayload.state === null) delete newPayload.state;

  return {
    ...newPayload,
  };
};

export const createSpeaker = createAsyncThunk('speaker/create', async (_, { getState, rejectWithValue }) => {
  try {
    const payload = getState().speakers.modals.create.data;
    return await speakersAPI.createSpeaker({
      ...stripSpeakerForSubmit(payload),
    });
  } catch (err) {
    console.log('Err', err);
    return rejectWithValue(err.response?.data?.error) || "Couldn't create speaker";
  }
});

export const generateFilterPayloadRequest = newQuery => {
  if (newQuery?.location) {
    if (newQuery.location.country) newQuery.country = newQuery.location.country;
    if (newQuery.location.state) newQuery.state = newQuery.location.state;
    if (newQuery.location.city) newQuery.city = newQuery.location.city;
    delete newQuery.location;
  } else delete newQuery.location;
  if (!newQuery.field) delete newQuery.field;
  if (!newQuery.speciality || !newQuery.speciality.length) delete newQuery.speciality;
  if (!newQuery?.text) delete newQuery.text;
  if (!newQuery?._id) delete newQuery._id;
  if (!newQuery?.status) delete newQuery.status;
  return newQuery;
};
export const refreshSpeakers = createAsyncThunk('speaker/refresh', async (_, { getState, rejectWithValue }) => {
  const { query } = getState().speakers;
  const newQuery = { ...query };
  try {
    const res = await speakersAPI.getSpeakers(generateFilterPayloadRequest(newQuery));

    const newRes = {
      totalCount: Number(res.paging.totalCount),
      results: res.hits,
    };
    return newRes;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response?.data?.error) || "Couldn't get speakers";
  }
});
const stripeSpeakerForEdit = payload => {
  const { __v, createdAt, updatedAt, country, city, state, slug, ...data } = payload;

  const newData = { ...data };

  const location = {};
  if (country) {
    location.country = country;
  }
  if (state) {
    location.state = state;
  }
  if (city) {
    location.city = city;
  }

  newData.location = location;

  return {
    ...newData,
  };
};
export const editSpeaker = createAsyncThunk('speaker/edit', async (_, { getState, rejectWithValue }) => {
  try {
    let payload = getState().speakers.modals.create.data;
    payload = stripSpeakerForSubmit(payload);
    console.log('payload', payload);

    return await speakersAPI.editSpeaker(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || "Couldn't edit speaker";
  }
});

const SpeakerStatusMap = {
  [speakersAPI.SpeakerStatus.Pending]: 'pending',
  [speakersAPI.SpeakerStatus.Published]: 'published',
};

export const setSpeakerStatus = createAsyncThunk('speakers/setStatus', ({ _id, status }) => {
  return speakersAPI.setSpeakerStatus(_id, SpeakerStatusMap[status]);
});

const speakersSlice = createSlice({
  name: 'speakers',
  initialState,
  reducers: {
    openCreateSpeakerModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateSpeakerModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            visible: false,
          },
        },
      };
    },
    openEditSpeakerModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: stripeSpeakerForEdit({
              ...payload,
            }),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchSpeakerModalData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
  },
  extraReducers: {
    [setSpeakerStatus.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setSpeakerStatus.fulfilled.type]: (state, { payload }) => {
      const values = Object.values(SpeakerStatusMap);
      const keys = Object.keys(SpeakerStatusMap);
      const status = keys[values.indexOf(payload.status)] || payload.status;
      return {
        ...state,
        ...speakerAdapter.updateOne({ ...state }, { id: payload._id, changes: { ...payload, status } }),
        loading: false,
        error: null,
      };
    },
    [setSpeakerStatus.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [refreshSpeakers.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshSpeakers.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...speakerAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshSpeakers.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },

    [createSpeaker.pending.type]: state => {
      state = { ...state, ...speakerAdapter.removeAll(state), loading: true, error: null };
    },
    [createSpeaker.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Congratulations!',
        `Speaker ${payload.name.substr(0, 25)} created successfully`,
      );
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
    [createSpeaker.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    },
    [editSpeaker.pending.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [editSpeaker.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Done!', `Speaker ${payload.name.substr(0, 25)} edited successfully`);
      return {
        ...state,
        ...speakerAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editSpeaker.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },

    [deleteSpeaker.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteSpeaker.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Done!', `Speaker Deleted successfully`);
      return {
        ...state,
        ...speakerAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteSpeaker.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
  },
});

export const speakersSelectors = speakerAdapter.getSelectors(state => state.speakers);

export const { patchSpeakerModalData, openCreateSpeakerModal, closeCreateSpeakerModal, openEditSpeakerModal } =
  speakersSlice.actions;

export default speakersSlice.reducer;
