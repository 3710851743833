import config from 'environment/config';
import axios from './axios';

export interface Hotel {
  _id: string;
  title: string;
  description: string;
  country: string;
  city: string;
  slug: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  image: string;
  lat: number;
  lng: number;
  fees: {
    value: number;
    name: string;
  }[];
  tax: number;
  breakfast: boolean;
  wifi: boolean;
}

export interface HotelsQuery {
  searchText?: string;
  pageNumber: number;
  status?: string[];
}
export const getHotelReservations = (query: HotelsQuery = { pageNumber: 1 }): Promise<Hotel[]> =>
  axios
    .get('reservation', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);

// export const getHotel = (id: string) => axios.get(`hotel?_id=${id}`).then(res => res.data.hotels[0]);

// export const createHotel = payload => axios.post('hotel', payload).then(res => res.data);

// export const editHotel = ({ _id, ...payload }) => axios.put(`hotel/${_id}`, payload).then(res => res.data);

// export const deleteHotel = id => axios.delete(`hotel/${id}`).then(res => res.data);
