import axios from './axios';

export enum ContactRequestType {
  Promote = 'promote',
  Contact = 'contact',
}

export const ListContactRequests = (type: ContactRequestType, page = 0) => {
  return axios.get('/contact-requests', { params: { type, page } }).then(res => res.data);
};

export const SetContactRequestResolved = (_id, resolved) => {
  return axios.put(`/contact-requests/${_id}`, { resolved }).then(res => res.data);
};
