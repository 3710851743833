import config from 'environment/config';
import axios from './axios';

export enum hotelPerType {
  PerPerson = 'perPersonPerNight',
  PerRoom = 'perRoomPerNight',
}
export const hotelPerTypeMap = {
  [hotelPerType.PerPerson]: 'Per person',
  [hotelPerType.PerRoom]: 'Per room',
};
export interface Hotel {
  _id: string;
  title: string;
  description: string;
  country: string;
  city: string;
  slug: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  image: string;
  lat: number;
  lng: number;
  fees: {
    value: number;
    perType: hotelPerType;
    name: string;
  }[];
  tax: number;
  breakfast: boolean;
  wifi: boolean;
}

export interface HotelsQuery {
  searchText?: string;
  pageNumber: number;
  status?: string[];
}

// export const getHotels = (query: HotelsQuery = { pageNumber: 1 }): Promise<Hotel[]> => {
//   const newQuery = { _page: query.pageNumber, _limit: config.pageSize, q: query.searchText };
//   return Axios.get('http://localhost:3001/hotel', {
//     params: {
//       pageSize: config.pageSize,
//       ...newQuery,
//     },
//   });
// };

// export const getHotel = (id: string) => Axios.get(`http://localhost:3001/hotel/${id}`).then(res => res.data);

// export const createHotel = payload => Axios.post('http://localhost:3001/hotel', payload).then(res => res.data);

// export const editHotel = ({ _id, ...payload }) =>
//   Axios.put(`http://localhost:3001/hotel/${_id}`, payload).then(res => res.data);

// export const deleteHotel = id => Axios.delete(`http://localhost:3001/hotel/${id}`).then(res => res.data);

export const getHotels = (query: HotelsQuery = { pageNumber: 1 }): Promise<Hotel[]> =>
  axios
    .get('hotel', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);

export const getHotel = (id: string) => axios.get(`hotel?_id=${id}`).then(res => res.data.hotels[0]);

export const createHotel = payload => axios.post('hotel', payload).then(res => res.data);

export const editHotel = ({ _id, ...payload }) => axios.put(`hotel/${_id}`, payload).then(res => res.data);

export const deleteHotel = id => axios.delete(`hotel/${id}`).then(res => res.data);
