/* eslint-disable no-console */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as careersApplicantsApi from 'api/careersApplicants';
import { NotificationType } from 'environment/constants';
import { showNotification } from 'util/show-notification';

const careersApplicantsAdapter = createEntityAdapter({
  selectId: career => career._id,
});

const errors = {
  get: 'Unable to get careers applicants!',
  create: 'Unable to create new career applicants!',
  edit: 'Unable to edit the career applicants!',
  search: 'Unable to search careers applicants!',
  delete: 'Unable to delete career applicants!',
};

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        title: '',
        shortDescription: '',
        description: '',
        country: '',
        state: null,
        city: '',
        responsibilities: null,
        requirements: null,
        open: true,
      },
      visible: false,
      editing: false,
    },
  },
  filterQuery: {},
  filteredHotels: [],
};

const initialState = careersApplicantsAdapter.getInitialState(defaultState);

const stripeCareerForSubmit = payload => {
  const newPayload = { ...payload };

  return {
    ...newPayload,
  };
};

export const editCareer = createAsyncThunk('careersApplicants/edit', async (payload, { getState, rejectWithValue }) => {
  try {
    return await careersApplicantsApi.editCareer(payload);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.edit;
  }
});

export const deleteCareer = createAsyncThunk('careersApplicants/delete', async (id, { rejectWithValue }) => {
  try {
    return await careersApplicantsApi.deleteCareer(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || errors.delete;
  }
});

export const refreshCareersApplicants = createAsyncThunk(
  'careersApplicants/refresh',
  async (_, { getState, rejectWithValue }) => {
    const { query } = getState().careersApplicants;
    const newQuery = { ...query };
    try {
      if (!newQuery?.text) delete newQuery.text;
      if (!newQuery?._id) delete newQuery._id;
      if (!newQuery?.status) delete newQuery.status;
      const res = await careersApplicantsApi.getCareers(newQuery);
      const newRes = {
        totalCount: Number(res.totalCount),
        results: res.jobApps,
      };
      return newRes;
    } catch (err) {
      console.log(err);
      return rejectWithValue(err.response?.data?.message) || errors.get;
    }
  },
);

const stripCareerForEdit = payload => {
  const newPayload = { ...payload };
  return newPayload;
};

const CareersApplicantsSlice = createSlice({
  name: 'careersApplicants',
  initialState,
  reducers: {
    openCreateCareerModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateCareerModal(state) {
      return {
        ...state,
        modals: defaultState.modals,
      };
    },
    openEditCareerModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: stripCareerForEdit(payload),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchCareerModalData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
  },
  extraReducers: {
    [refreshCareersApplicants.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshCareersApplicants.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...careersApplicantsAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshCareersApplicants.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [deleteCareer.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteCareer.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Success!', `Career Applicant Deleted Successfully`);
      return {
        ...state,
        ...careersApplicantsAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteCareer.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);
      return { ...state, loading: false, error: null };
    },
    [editCareer.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [editCareer.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Done!',
        `Career Applicant for ${payload.user.userName.substr(0, 25)} edited successfully`,
      );
      return {
        ...state,
        ...careersApplicantsAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editCareer.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action.payload);

      return { ...state, loading: false, error: null };
    },
  },
});

export const careersApplicantsSelectors = careersApplicantsAdapter.getSelectors(state => state.careersApplicants);

export const { patchCareerModalData, openCreateCareerModal, closeCreateCareerModal, openEditCareerModal } =
  CareersApplicantsSlice.actions;

export default CareersApplicantsSlice.reducer;
