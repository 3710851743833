import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as eventsAPI from 'api/events';
import dayjs from 'dayjs';
import { NotificationType } from 'environment/constants';
import { ListEventsQuery } from 'graph/events/events.query';
import { graphQLClient } from 'graph/GraphQLClient';
import { showNotification } from 'util/show-notification';
import { EventType } from '../../api/events';
import i18n from '../../i18n';

const eventAdapter = createEntityAdapter({
  selectId: event => event._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        agendaURL: '',
        isTimeHidden: false,
        title: '',
        ranking: 50,
        email: '',
        bussinessPageId: undefined,
        description: '',
        fees: 1,
        eventTime: [{ startDate: null, endDate: null }],
        // profession: null,
        field: null,
        speciality: null,
        tags: [],
        tickets: [
          {
            name: '',
            qty: undefined,
            price: 0,
            currency: 'USD',
            description: undefined,
            startDate: '',
            endDate: '',
            suspended: false,
          },
        ],
        refundPolicyId: null,
        serviceFees: 0,
        isFreeOnlineEvent: false,
        image: {},
        location: {
          country: '',
          city: '',
          area: '',
          address: '',
          lat: null,
          lng: null,
          addressGoogle: '',
        },
        bookLink: '',
        meetingRoom: '',
        speakerLang: 'English',
        type: EventType.Workshop,
        faq: [],
        gallery: [],
        instructors: [],
        speakers: [],
        eventContents: [],
        skills: [],
        coupons: undefined,
        discounts: undefined,
        meta: {
          title: '',
          description: '',
          canonical: '',
        },
      },
      visible: false,
      editing: false,
    },
    couponsAndDisc: {
      eventId: null,
      visible: false,
    },
  },
  filterQuery: {},
  filteredEvents: [],
};

const initialState = eventAdapter.getInitialState(defaultState);

const EventStatusMap = {
  [eventsAPI.EventStatus.Pending]: 'pending',
  [eventsAPI.EventStatus.Suspended]: 'suspend',
  [eventsAPI.EventStatus.Published]: 'published',
  [eventsAPI.EventStatus.Draft]: 'draft',
  [eventsAPI.EventStatus.Declined]: 'declined',
  [eventsAPI.EventStatus.Closed]: 'closed',
};

const turnToIsoString = date => (date ? new Date(date).toISOString() : new Date().toISOString());

const handleEventTime = eventTimes => {
  return eventTimes.map(({ startDate, endDate }) => {
    return { startDate: turnToIsoString(startDate), endDate: turnToIsoString(endDate) };
  });
};

const datesToIsoMiddleware = payload => {
  const tickets = payload.tickets?.map(ticket => {
    if (!ticket?.startDate || !ticket?.endDate) {
      return {
        ...ticket,
        startDate: turnToIsoString(),
        endDate: turnToIsoString(payload.eventTime[0].endDate),
      };
    }
    return {
      ...ticket,
      startDate: turnToIsoString(ticket.startDate),
      endDate: turnToIsoString(ticket.endDate),
    };
  });
  return { ...payload, tickets, eventTime: handleEventTime(payload.eventTime) };
};

const defaultsMiddleware = payload => {
  if (payload.speciality === null) {
    payload.speciality = [];
  }
  return payload;
};

const handleEventLocation = payload =>
  eventsAPI.isEventOnline(payload.type) ? { ...payload, location: null } : payload;

const eventMiddleWares = payload => {
  payload = handleEventLocation(payload);
  payload = datesToIsoMiddleware(payload);
  if (payload.isTimeHidden === null || payload.isTimeHidden === undefined) payload.isTimeHidden = false;
  if (payload.speakers) payload.speakers = payload.speakers.map(v => v.value ?? v._id);
  if (payload.instructors)
    payload.instructors = payload.instructors.map(item => {
      const newItem = { ...item };
      if (!newItem.country) delete newItem.country;
      if (!newItem.city) delete newItem.city;
      if (!newItem.state) delete newItem.state;
      return newItem;
    });
  if (payload.pendingSpeakers)
    payload.pendingSpeakers = payload.pendingSpeakers.map(item => {
      const newItem = { ...item };
      if (!newItem.country) delete newItem.country;
      if (!newItem.city) delete newItem.city;
      if (!newItem.state) delete newItem.state;
      return newItem;
    });
  return defaultsMiddleware(payload);
};
export const searchEvents = createAsyncThunk('events/search', async (_, { getState, rejectWithValue }) => {
  try {
    const query = getState().events.filterQuery;
    const result = await graphQLClient.query({
      query: ListEventsQuery,
      variables: query,
      fetchResults: true,
    });
    return result.data.search;
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('events:errors:search');
  }
});

export const createEvent = createAsyncThunk('events/create', async (_, { getState, rejectWithValue }) => {
  try {
    const payload = getState().events.modals.create.data;
    return await eventsAPI.createEvent(eventMiddleWares(payload));
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('events:errors:create');
  }
});

export const editEvent = createAsyncThunk('events/edit', async (_, { getState, rejectWithValue }) => {
  try {
    const { businessPage, status, ...payload } = getState().events.modals.create.data;

    if (payload?.serviceFees) {
      if (payload?.serviceFees > 1) {
        payload.serviceFees = payload?.serviceFees / 100;
      }
    }

    let res = await eventsAPI.editEvent(eventMiddleWares(payload));

    const changeStatus =
      payload.eventTime.some(({ endDate }) => {
        return dayjs(endDate).isAfter(dayjs());
      }) && status === eventsAPI.EventStatus.Closed;

    if (changeStatus) {
      res = await eventsAPI.setEventStatus(payload._id, EventStatusMap[eventsAPI.EventStatus.Published]);
    }

    return res;
  } catch (err) {
    console.log(err);
    return rejectWithValue(err.response?.data?.error) || i18n.t('events:errors:edit');
  }
});

export const deleteEvent = createAsyncThunk('events/delete', async (id, { rejectWithValue }) => {
  try {
    return await eventsAPI.deleteEvent(id);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error) || i18n.t('events:errors:delete');
  }
});

export const generateFilterPayloadRequest = payload => {
  let query = { ...payload };
  if (query && query.scraped === 'all') {
    query = { ...query, scraped: null };
  }

  if (query.scraped === undefined) {
    query = { ...query, scraped: null };
  }

  if (query.speciality && query.speciality.length > 0) {
    const { field, ...rest } = query;
    query = rest;
  }

  if (query.query) {
    query = {
      ...query,
      query: String(query.query)
        .replaceAll(')', '\\)')
        .replaceAll('(', '\\(')
        .replaceAll('[', '\\[')
        .replaceAll(']', '\\]')
        .trim(),
    };
  }

  return query;
};

export const refreshEvents = createAsyncThunk('events/refresh', async (_, { getState }) => {
  try {
    const { query } = getState().events;

    const result = await graphQLClient.query({
      query: ListEventsQuery,
      variables: {
        ...generateFilterPayloadRequest(query),
      },
      fetchResults: true,
    });
    return result.data.search;
  } catch (error) {
    console.error(error);
    return null;
  }
});

// eslint-disable-next-line consistent-return
export const refreshEventCoupons = createAsyncThunk('events/coupons/refresh', async (id, { rejectWithValue }) => {
  try {
    if (id) {
      return await eventsAPI.getEventCoupons(id);
    }
    return [];
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

// eslint-disable-next-line consistent-return
export const refreshEventDiscounts = createAsyncThunk('events/disocunts/refresh', async (id, { rejectWithValue }) => {
  try {
    if (id) {
      return await eventsAPI.getEventDiscounts(id);
    }
    return [];
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

// #region DEPRECATED - Used for infinite scrolling
export const fetchMoreEvents = createAsyncThunk('events/fetchMore', async (_, { getState }) => {
  const state = getState().events;
  return eventsAPI.getEvents(state.query);
});
// #endregion

export const setEventStatus = createAsyncThunk('events/setStatus', ({ _id, status }) => {
  return eventsAPI.setEventStatus(_id, EventStatusMap[status]);
});

export const setEventFeatured = createAsyncThunk('events/setVerify', async ({ _id, featured }, { rejectWithValue }) => {
  try {
    return await eventsAPI.setVerify(_id, featured);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

export const setEventPremium = createAsyncThunk('events/setPremium', async ({ _id, premium }, { rejectWithValue }) => {
  try {
    return await eventsAPI.setPremium(_id, premium);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

export const setEventScraped = createAsyncThunk('events/setScraped', async ({ _id, scraped }, { rejectWithValue }) => {
  try {
    return await eventsAPI.setScraped(_id, scraped);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

const stripEventForEdit = payload => {
  const {
    startDate,
    endDate,
    __v,
    bussinessPageId,
    coverUrl,
    createdBy,
    createdAt,
    updatedAt,
    currency,
    slug,
    tickets,
    featured,
    ...data
  } = payload;

  return {
    ...data,

    tickets: tickets.map(({ coupons, ...ticket }) => ({ ...ticket })),
  };
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    openCreateEventModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateEventModal(state) {
      return {
        ...state,
        modals: defaultState.modals,
      };
    },
    openEditEventModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: stripEventForEdit(payload),
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchEventModalData(state, { payload }) {
      if (payload.undefined) delete payload.undefined;
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
    openEventCpnAndDiscModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          couponsAndDisc: {
            eventId: payload?._id,
            visible: true,
          },
        },
      };
    },
    closeEventCpnAndDiscModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          couponsAndDisc: {
            visible: false,
            eventId: null,
          },
        },
      };
    },
  },
  extraReducers: {
    [searchEvents.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, filterQuery: { ...state.filterQuery, ...query } };
    },
    [searchEvents.fulfilled.type]: (state, { payload: { results } }) => {
      return {
        ...state,
        loading: false,
        filteredEvents: results,
      };
    },
    [searchEvents.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [refreshEvents.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshEvents.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...eventAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshEvents.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    // #region DEPRECATED - Used for infinite scrolling
    [fetchMoreEvents.pending.type]: state => {
      const { length } = eventAdapter.getSelectors().selectAll(state);
      return {
        ...state,
        ...(state.totalCount > length && { query: { ...state.query, pageNumber: state.query.pageNumber + 1 } }),
        loading: true,
        error: null,
      };
    },
    [fetchMoreEvents.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...eventAdapter.addMany({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [fetchMoreEvents.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    // #endregion
    [setEventStatus.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setEventStatus.fulfilled.type]: (state, { payload }) => {
      const values = Object.values(EventStatusMap);
      const keys = Object.keys(EventStatusMap);
      const status = keys[values.indexOf(payload.status)] || payload.status;
      return {
        ...state,
        ...eventAdapter.updateOne({ ...state }, { id: payload._id, changes: { ...payload, status } }),
        loading: false,
        error: null,
      };
    },
    [setEventStatus.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [deleteEvent.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [deleteEvent.fulfilled.type]: (state, { meta: { arg: id } }) => {
      showNotification(NotificationType.Success, 'Success!', `Event Deleted Successfully`);
      return {
        ...state,
        ...eventAdapter.removeOne({ ...state }, id),
        loading: false,
        error: null,
      };
    },
    [deleteEvent.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
    [createEvent.pending.type]: state => ({ ...state, loading: true, error: null }),
    [createEvent.fulfilled.type]: (state, { payload }) => {
      showNotification(
        NotificationType.Success,
        'Congratulations!',
        `Event ${payload.title.substr(0, 25)} added successfully`,
      );
      return { ...state, loading: false, error: null };
    },
    [createEvent.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
    [editEvent.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [editEvent.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Done!', `Event ${payload.title.substr(0, 25)} edited successfully`);
      return {
        ...state,
        ...eventAdapter.updateOne({ ...state }, { id: payload._id, changes: payload }),
        loading: false,
        error: null,
      };
    },
    [editEvent.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
    [refreshEventCoupons.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [refreshEventCoupons.fulfilled.type]: (state, { payload }) =>
      payload && {
        ...state,
        ...eventAdapter.updateOne({ ...state }, { id: payload.eventId, changes: { coupons: payload.coupons } }),
        loading: false,
        error: null,
      },
    [refreshEventCoupons.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [refreshEventDiscounts.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [refreshEventDiscounts.fulfilled.type]: (state, { payload }) =>
      payload && {
        ...state,
        ...eventAdapter.updateOne({ ...state }, { id: payload.eventId, changes: { discounts: payload.discounts } }),
        loading: false,
        error: null,
      },
    [refreshEventDiscounts.rejected.type]: state => {
      return { ...state, loading: false, error: null };
    },
    [setEventFeatured.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setEventFeatured.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Success!', `Changed Event feature property successfully`);
      return {
        ...state,
        ...eventAdapter.updateOne(
          { ...state },
          { id: payload._id, changes: { ...payload, featured: payload.featured } },
        ),
        loading: false,
        error: null,
      };
    },
    [setEventFeatured.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
    [setEventPremium.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setEventPremium.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Success!', `Changed Event premium property successfully`);
      return {
        ...state,
        ...eventAdapter.updateOne({ ...state }, { id: payload._id, changes: { ...payload, premium: payload.premium } }),
        loading: false,
        error: null,
      };
    },
    [setEventPremium.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },

    [setEventScraped.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [setEventScraped.fulfilled.type]: (state, { payload }) => {
      showNotification(NotificationType.Success, 'Success!', `Changed Event scraped property successfully`);
      return {
        ...state,
        ...eventAdapter.updateOne({ ...state }, { id: payload._id, changes: { ...payload, scraped: payload.scraped } }),
        loading: false,
        error: null,
      };
    },
    [setEventScraped.rejected.type]: (state, action) => {
      showNotification(NotificationType.Error, 'Error!', action?.payload);
      return { ...state, loading: false, error: null };
    },
  },
});

export const eventsSelectors = eventAdapter.getSelectors(state => state.events);

export const {
  patchEventModalData,
  openCreateEventModal,
  closeCreateEventModal,
  openEditEventModal,
  openEventCpnAndDiscModal,
  closeEventCpnAndDiscModal,
} = eventsSlice.actions;

export default eventsSlice.reducer;
