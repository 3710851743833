import config from 'environment/config';
import axios from './axios';

export enum BusinessPageStatus {
  Pending = 'pending',
  Declined = 'declined',
  Active = 'active',
  Suspended = 'suspend',
  Draft = 'draft',
}

export enum BusinessPageLicense {
  Yes = 'yes',
  No = 'no',
}

export interface BusinessPage {
  _id: string;
  name: string;
  status: BusinessPageStatus;
  email: string;
  createdAt: Date;
  license: string[];
  field: string;
  rootUserId: string;
}

export interface BusinessPagesQuery {
  searchText?: string;
  pageNumber: number;
  status?: string[];
}

export const getBusinessPages = (query: BusinessPagesQuery = { pageNumber: 1 }): Promise<BusinessPage[]> =>
  axios
    .get('businessPages', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);

export const getBusinessPage = (id: string) => axios.get(`businessPages/${id}`);

export const createBusinessPage = (payload: BusinessPage) => axios.post(`businessPages`, payload).then(res => res.data);

export const setBusinessPageStatus = (id: string, status: BusinessPageStatus): Promise<BusinessPage> =>
  axios.put(`businessPages/${id}/status`, { status }).then(res => res.data);

export const editBusinessPage = ({ _id, ...payload }) => {
  return axios.patch(`businessPages/${_id}`, payload).then(res => res.data);
};

export const checkBusPageName = async ({ name, id = null }) => {
  try {
    await axios.post('businessPages/check_name', {
      ...(id ? { id } : {}),
      name,
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const deleteBusinessPage = async id => axios.delete(`businessPages/${id}`).then(res => res.data);
