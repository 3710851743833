import config from 'environment/config';
import axios from './axios';

export const login = (payload: { email: string; password: string }) => axios.post(config.auth.loginEndpoint, payload);

export const setToken = (token: string) => {
  localStorage.setItem(config.auth.localStorageKey, token);
};

export const getToken = () => {
  return localStorage.getItem(config.auth.localStorageKey);
};

export const removeToken = () => {
  localStorage.removeItem(config.auth.localStorageKey);
};

export const refreshToken = async () => {
  const { data } = await axios.get(config.auth.refreshTokenEndpoint);
  setToken(data);
  return data;
};
