import config from 'environment/config';
import axios from './axios';

export interface ISpeakerPage {
  image: {
    webp: {
      card: string;
      thump: string;
      cover: string;
    };
    card: string;
    thump: string;
    cover: string;
  };
  _id: string;
  field?: string;
  speciality?: string[];
  name: string;
  bio: string;
  job: string;
  slug: string;
  country?: string;
  state?: string;
  city?: string;
}

export enum SpeakerStatus {
  Pending = 'pending',
  Published = 'published',
}

export interface SpeakersQuery {
  searchText?: string;
  pageNumber: number;
  pageSize?: number;
  status?: string[];
}

export const getSpeakers = (query: SpeakersQuery = { pageNumber: 1 }): Promise<ISpeakerPage[]> =>
  axios
    .post('speaker/search', {
      pageSize: config.pageSize,
      ...query,
    })
    .then(res => res.data);

export const getSpeaker = (id: string) => axios.get(`speaker/${id}`).then(res => res.data);

export const createSpeaker = (payload: ISpeakerPage) => axios.post(`speaker`, payload).then(res => res.data);

export const editSpeaker = ({ _id, ...payload }) => {
  return axios.put(`speaker/${_id}`, payload).then(res => res.data);
};

export const setSpeakerStatus = (id: string, status: SpeakerStatus): Promise<Event> => {
  return axios.put(`speaker/${id}`, { status }).then(res => res.data);
};

export const deleteSpeaker = async id => axios.delete(`speaker/${id}`).then(res => res.data);

export const exportSpeakers = async (filters: any) => {
  // eslint-disable-next-line no-return-await
  return axios.post(`speaker/export`, filters, { responseType: 'arraybuffer', timeout: 100 * 60 * 60 * 1000 });
};
