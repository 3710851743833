import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as lookupsAPI from 'api/lookups';

const initialState = {
  loading: {
    country: false,
    city: false,
    state: false,
    // profession: false,
    field: false,
    speciality: false,
    speakerLanguage: false,
  },
  error: null,
  country: [],
  city: [],
  state: [],
  // profession: [],
  field: [],
  speciality: [],
  speakerLanguage: [],
  stripped: {
    country: [],
    city: [],
    state: [],
    // profession: [],
    field: [],
    speciality: null,
    speakerLanguage: [],
  },
};

export const fetchLookups = createAsyncThunk('lookups/fetch', async (payload: any) => {
  if (payload?.type === lookupsAPI.LookupItemType.City) {
    payload.pageSize = 10000;
  }
  const lookups = await lookupsAPI.getLookups(payload);
  return lookups;
});

const lookupTypesMappings = {
  country: 'country',
  city: 'city',
  state: 'state',
  profession: 'profession',
  speciality: 'speciality',
  field: 'field',
  speaker_lang: 'speakerLanguage',
};

const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    resetLookups(state, { payload }) {
      const type = lookupTypesMappings[payload.type];
      return {
        ...state,
        loading: { ...state.loading, [type]: false },
        [type]: [],
        stripped: {
          ...state.stripped,
          [type]: null,
        },
      };
    },
    clearLookups(state, { payload }) {
      const type = lookupTypesMappings[payload.type];
      return {
        ...state,
        loading: { ...state.loading, [type]: false },
        [type]: [],
        stripped: {
          ...state.stripped,
          [type]: [],
        },
      };
    },
  },
  extraReducers: {
    [fetchLookups.pending.type]: (state, { meta }) => {
      const type = lookupTypesMappings[meta.arg.type];
      return { ...state, loading: { ...state.loading, [type]: true }, error: null };
    },
    [fetchLookups.fulfilled.type]: (state, { payload, meta }) => {
      const type = lookupTypesMappings[meta.arg.type];
      return {
        ...state,
        loading: { ...state.loading, [type]: false },
        error: null,
        [type]: payload.result,
        stripped: {
          ...state.stripped,
          [type]: payload.result.map(item => item.id),
        },
      };
    },
    [fetchLookups.rejected.type]: (state, { meta }) => {
      const type = lookupTypesMappings[meta.arg.type];
      return { ...state, loading: { ...state.loading, [type]: false }, error: null };
    },
  },
});

export const { resetLookups, clearLookups } = lookupsSlice.actions;

export default lookupsSlice.reducer;
