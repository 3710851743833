import gql from 'graphql-tag';

export const BookedTicketEntityFragment = gql`
  fragment bookedTicket on BookedTicketEntity {
    _id
    providerId
    event {
      _id
      title
      status
      slug
      scrapedHost
    }
    userEmail
    userPhone
    userName
    businessPage {
      name
    }
    amountWithCurrency
    totalAmountRated
    paymentIntegrationType
    status
    tickets {
      financialStatement {
        totalGrossSales
        totalCoupon
        totalDiscount
        totalNetSales
        totalProcessingFees
        totalDebitCoatConnectFees
        totalCreditCoatConnectCouponFees
        totalCreditCoatConnectDiscountFees
        totalNet
        totalNetRated
      }
      totalAmount
      totalAmountRated
      qty
      price
      currency
      name
      _id
    }
    eventStartDate
    totalAmount
    currency
    providerOrder {
      vodafoneCashNumber
    }
    createdAt
  }
`;

export const ListEventBookingsQuery = gql`
  query listEventBookings(
    $pageSize: Float
    $pageNumber: Float
    $query: String
    $providerId: String
    $currency: [String!]
    $paymentIntegrationType: [String!]
    $businessPages: [String!]
    $events: [String!]
    $date: [DateTime!]
    $sort: BookedTicketSortObject
  ) {
    listEventBookings(
      pageSize: $pageSize
      pageNumber: $pageNumber
      query: $query
      providerId: $providerId
      currency: $currency
      paymentIntegrationType: $paymentIntegrationType
      businessPages: $businessPages
      events: $events
      date: $date
      sort: $sort
    ) {
      results {
        ...bookedTicket
      }
      totalCount
    }
  }
  ${BookedTicketEntityFragment}
`;
