import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import PrivateRoute from 'components/shared/PrivateRoute';
import LoadingPage from 'pages/Loading/LoadingPage';
import { useSelector, useDispatch } from 'react-redux';
import { refreshUserToken } from 'store/users/authSlice';

const LoginPage = React.lazy(() => import('pages/LoginPage.jsx'));
const Dashboard = React.lazy(() => import('pages/Dashboard/Dashboard.tsx'));

export default function Routes() {
  const refreshingToken = useSelector(state => state.auth.refreshingToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const process = async () => {
      const result = await dispatch(refreshUserToken());
      if (!result.error && !location.pathname.startsWith('/dashboard')) {
        history.push('/dashboard');
      }
    };
    process();
  }, [dispatch, history]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {refreshingToken ? (
        <LoadingPage />
      ) : (
        <Switch>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <Route path="/login" component={LoginPage} />
          <Route path="/">
            <Redirect to="/login" />
          </Route>
        </Switch>
      )}
    </>
  );
}
