import config from 'environment/config';
import axios from './axios';

export interface Career {
  _id: string;
  slug: string;
  title: string;
  shortDescription: string;
  description: string;
  country: string;
  state: string;
  city: string;
  responsibilities: string[];
  requirements: string[];
  open: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CareerQuery {
  searchText?: string;
  pageNumber: number;
  open?: boolean;
}

export const getCareers = (query: CareerQuery = { pageNumber: 1 }): Promise<Career[]> => {
  return axios
    .get('/careers/jobs', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);
};

export const getCareer = (id: string) => axios.get(`/careers/jobs/${id}`).then(res => res.data);

export const createCareer = payload => axios.post('/careers/jobs', payload).then(res => res.data);

export const editCareer = ({ _id, ...payload }) => axios.put(`/careers/job/${_id}`, payload).then(res => res.data);

export const deleteCareer = id => axios.delete(`/careers/job/${id}`).then(res => res.data);
