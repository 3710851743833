import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, refreshToken, getToken, setToken } from 'api/auth';

export interface LoginPayload {
  email: string;
  password: string;
}

export interface User {
  _id: string;
  name: string;
  email: string;
}

export interface AuthState {
  refreshingToken: boolean;
  loggedIn: boolean;
  loading: boolean;
  error: boolean;
}

const initialState: AuthState = {
  refreshingToken: true,
  loggedIn: false,
  loading: false,
  error: false,
};

export const loginUser = createAsyncThunk('auth/login', async (payload: LoginPayload) => {
  const response = await login(payload);
  setToken(response.data);
  return response.data;
});

export const refreshUserToken = createAsyncThunk('auth/refresh', async () => {
  if (getToken()) {
    await refreshToken();
  } else {
    throw new Error('User is not logged in.');
  }
});

export const logoutUser = createAsyncThunk('auth/logout', async () => {
  setToken('');
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [loginUser.pending.type]: state => {
      return { ...state, loading: true, error: false };
    },
    [loginUser.fulfilled.type]: state => {
      return { ...state, loading: false, loggedIn: true };
    },
    [loginUser.rejected.type]: state => {
      return { ...state, loading: false, error: false };
    },
    [refreshUserToken.pending.type]: state => {
      return { ...state, loading: true, refreshingToken: true, error: false };
    },
    [refreshUserToken.fulfilled.type]: state => {
      return { ...state, loading: false, refreshingToken: false, loggedIn: true };
    },
    [refreshUserToken.rejected.type]: state => {
      return { ...state, loading: false, refreshingToken: false, error: false };
    },
    [logoutUser.pending.type]: state => {
      return { ...state, loading: true, error: false };
    },
    [logoutUser.fulfilled.type]: state => {
      return { ...state, loading: false, error: false, loggedIn: false };
    },
    [logoutUser.rejected.type]: state => {
      return { ...state, loading: false, error: true };
    },
  },
});

export default authSlice.reducer;
