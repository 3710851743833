import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute({ component: Component = undefined, redirect = undefined, ...rest }) {
  const isAuthenticated = useSelector(state => state.auth.loggedIn);

  if (!Component && redirect) {
    return <Route {...rest} render={() => (isAuthenticated ? <Redirect to={redirect} /> : <Redirect to="/login" />)} />;
  }

  if (!Component) {
    throw new Error('No component defined for private route!');
  }

  return <Route {...rest} render={props => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)} />;
}
