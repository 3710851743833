import config from 'environment/config';
import axios from './axios';

export interface Hotel {
  _id: string;
  currency: string;
  allowedEvents: {
    _id: string;
    title: string;
    slug: string;
  }[];
  title: string;
  description: string;
  hotel: {
    _id: string;
    slug: string;
    name: string;
  };
  country: string;
  city: string;
  state: string;
  images: any[];
  quantity: number;
  capacityPrice: any[];
  createdAt: Date;
  updatedAt: Date;
  __v: 0;
}

export interface HotelsRoomsQuery {
  searchText?: string;
  pageNumber: number;
  status?: string[];
}

// export const getHotelsRooms = (query: HotelsRoomsQuery = { pageNumber: 1 }): Promise<Hotel[]> => {
//   const newQuery = { _page: query.pageNumber, _limit: config.pageSize, q: query.searchText };
//   return Axios.get('http://localhost:3001/hotel', {
//     params: {
//       pageSize: config.pageSize,
//       ...newQuery,
//     },
//   });
// };

// export const getHotel = (id: string) => Axios.get(`http://localhost:3001/hotel/${id}`).then(res => res.data);

// export const createHotel = payload => Axios.post('http://localhost:3001/hotel', payload).then(res => res.data);

// export const editHotel = ({ _id, ...payload }) =>
//   Axios.put(`http://localhost:3001/hotel/${_id}`, payload).then(res => res.data);

// export const deleteHotel = id => Axios.delete(`http://localhost:3001/hotel/${id}`).then(res => res.data);

export const getHotelsRooms = (query: HotelsRoomsQuery = { pageNumber: 1 }): Promise<Hotel[]> =>
  axios
    .get('hotel/room', {
      params: {
        pageSize: config.pageSize,
        ...query,
      },
    })
    .then(res => res.data);

export const getHotelRoom = (id: string) => axios.get(`hotel/room?_id=${id}`).then(res => res.data.rooms[0]);

export const createHotelRooms = payload => axios.post('hotel/room', payload).then(res => res.data);

export const editHotelRooms = ({ _id, ...payload }) => axios.put(`hotel/room/${_id}`, payload).then(res => res.data);

export const deleteHotelRooms = id => axios.delete(`hotel/room/${id}`).then(res => res.data);
