import axios from './axios';

export enum TransactionStatus {
  NotPaid = 'NotPaid',
  PartiallyPaid = 'PartiallyPaid',
  Paid = 'Paid',
}

export enum ShowFreeStatus {
  Yes = 'yes',
  No = 'no',
}

export const createTransaction = payload => axios.post('transactions', payload).then(res => res.data);

export const editTransaction = ({ _id, ...payload }) =>
  axios.patch(`transactions/${_id}`, payload).then(res => res.data);

export const deleteTransaction = payload => axios.delete(`transactions/${payload._id}`).then(res => res.data);
