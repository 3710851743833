import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import * as adsAPI from 'api/ads';

const adAdapter = createEntityAdapter({
  selectId: ad => ad._id,
});

const defaultState = {
  loading: false,
  error: null,
};

const initialState = adAdapter.getInitialState(defaultState);

export const getAds = createAsyncThunk('ads/refresh', async (_, { getState, rejectWithValue }) => {
  try {
    return await adsAPI.getAds();
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

export const saveAds = createAsyncThunk('ads/create', async (values, { getState, rejectWithValue }) => {
  try {
    return await adsAPI.saveAds(values);
  } catch (err) {
    return rejectWithValue(err.response?.data?.error);
  }
});

const adsSlice = createSlice({
  name: 'ads',
  initialState,
  extraReducers: {
    [getAds.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [getAds.fulfilled.type]: (state, { payload: { ads } }) => {
      return {
        ...adAdapter.setAll({ ...state }, ads),
        loading: false,
      };
    },
    [getAds.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
    [saveAds.pending.type]: state => {
      return { ...state, loading: true, error: null };
    },
    [saveAds.fulfilled.type]: (state, { payload }) => {
      return {
        ...adAdapter.setAll({ ...state }, payload),
        loading: false,
      };
    },
    [saveAds.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
  },
});

export const adsSelectors = adAdapter.getSelectors(state => state.ads);

export default adsSlice.reducer;
