import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { ListTransactionsQuery } from 'graph/transactions/transactions.query';
import { graphQLClient } from 'graph/GraphQLClient';
import { cleanObject } from 'util/remove-falsy';
import * as transactionsAPI from 'api/transactions';
import { showNotification } from 'util/show-notification';
import { NotificationType } from 'environment/constants';

const transactionAdapter = createEntityAdapter({
  selectId: transaction => transaction._id,
});

const defaultState = {
  loading: false,
  error: null,
  totalCount: 0,
  query: {
    pageNumber: 1,
  },
  modals: {
    create: {
      data: {
        amount: 0,
        pictureUrl: '',
      },
      visible: false,
      editing: false,
    },
    eventSummary: {
      eventId: null,
      visible: false,
    },
  },
};

const initialState = transactionAdapter.getInitialState(defaultState);

export const createTransaction = createAsyncThunk('transactions/create', (_, { getState }) => {
  const payload = getState().transactions.modals.create.data;
  return transactionsAPI.createTransaction(payload);
});

export const editTransaction = createAsyncThunk('transactions/edit', (_, { getState }) => {
  const { event, createdAt, ...payload } = getState().transactions.modals.create.data;
  return transactionsAPI.editTransaction(payload);
});

export const deleteTransaction = createAsyncThunk('transactions/delete', payload => {
  return transactionsAPI.deleteTransaction(payload);
});

export const refreshTransactions = createAsyncThunk('transactions/refresh', async (_, { getState }) => {
  const { query } = getState().transactions;
  const result = await graphQLClient.query({
    query: ListTransactionsQuery,
    variables: cleanObject(query),
    fetchResults: true,
  });
  return result.data.transactions;
});

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    openEventTransactionsModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          eventSummary: {
            eventId: payload.event?._id,
            visible: true,
          },
        },
      };
    },
    closeEventTransactionsModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          eventSummary: {
            ...state.modals.eventSummary,
            visible: false,
          },
        },
      };
    },
    openCreateTransactionModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: defaultState.modals.create.data,
            visible: true,
            editing: false,
          },
        },
      };
    },
    closeCreateTransactionModal(state) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            visible: false,
          },
        },
      };
    },
    openEditTransactionModal(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            data: payload,
            visible: true,
            editing: true,
          },
        },
      };
    },
    patchTransactionModalData(state, { payload }) {
      return {
        ...state,
        modals: {
          ...state.modals,
          create: {
            ...state.modals.create,
            data: {
              ...state.modals.create.data,
              ...payload,
            },
          },
        },
      };
    },
  },
  extraReducers: {
    [createTransaction.pending.type]: state => ({ ...state, loading: true, error: null }),
    [createTransaction.fulfilled.type]: state => {
      showNotification(NotificationType.Success, 'Success!', `Transaction created successfully`);
      return { ...state, loading: false, error: null };
    },
    [createTransaction.rejected.type]: state => {
      showNotification(NotificationType.Error, 'Error!', 'An error has occurred');
      return { ...state, loading: false, error: null };
    },
    [editTransaction.pending.type]: state => ({ ...state, loading: true, error: null }),
    [editTransaction.fulfilled.type]: state => {
      showNotification(NotificationType.Success, 'Success!', `Transaction updated successfully`);
      return { ...state, loading: false, error: null };
    },
    [editTransaction.rejected.type]: state => {
      showNotification(NotificationType.Error, 'Error!', 'An error has occurred');
      return { ...state, loading: false, error: null };
    },
    [deleteTransaction.pending.type]: state => ({ ...state, loading: true, error: null }),
    [deleteTransaction.fulfilled.type]: state => {
      showNotification(NotificationType.Success, 'Success!', `Transaction deleted successfully`);
      return { ...state, loading: false, error: null };
    },
    [deleteTransaction.rejected.type]: state => {
      showNotification(NotificationType.Error, 'Error!', 'An error has occurred');
      return { ...state, loading: false, error: null };
    },
    [refreshTransactions.pending.type]: (state, { meta: { arg: query } }) => {
      return { ...state, loading: true, error: null, query: { ...state.query, pageNumber: 1, ...query } };
    },
    [refreshTransactions.fulfilled.type]: (state, { payload: { results, totalCount } }) => {
      return {
        ...transactionAdapter.setAll({ ...state }, results),
        loading: false,
        totalCount,
      };
    },
    [refreshTransactions.rejected.type]: (state, { error }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },
  },
});

export const transactionsSelectors = transactionAdapter.getSelectors(state => state.transactions);

export const {
  patchTransactionModalData,
  openCreateTransactionModal,
  openEditTransactionModal,
  closeCreateTransactionModal,
  openEventTransactionsModal,
  closeEventTransactionsModal,
} = transactionSlice.actions;

export default transactionSlice.reducer;
