import gql from 'graphql-tag';

export const OrderEntityFragment = gql`
  fragment order on ProviderOrderEntity {
    _id
    providerId
    event {
      _id
      title
      slug
      scrapedHost
    }
    businessPage {
      _id
      name
    }
    totalAmount
    amountWithCurrency
    totalAmountRated
    orderStatus
    currency
    createdAt
    paymentIntegrationType
    userName
    userEmail
    userPhone
    tickets {
      financialStatement {
        totalGrossSales
        totalCoupon
        totalDiscount
        totalNetSales
        totalProcessingFees
        totalDebitCoatConnectFees
        totalCreditCoatConnectCouponFees
        totalCreditCoatConnectDiscountFees
        totalNet
        totalNetRated
      }
      totalAmount
      totalAmountRated
      qty
      price
      currency
      name
      _id
    }
    expiresIn
    vodafoneCashNumber
  }
`;

export const ORDERS_QUERY = gql`
  query orders(
    $pageSize: Float
    $pageNumber: Float
    $query: String
    $providerId: String
    $currency: [String!]
    $paymentIntegrationType: [String!]
    $businessPages: [String!]
    $events: [String!]
    $orderStatus: [String!]
    $date: [DateTime!]
    $sort: BookedTicketSortObject
  ) {
    orders(
      pageSize: $pageSize
      pageNumber: $pageNumber
      query: $query
      providerId: $providerId
      currency: $currency
      paymentIntegrationType: $paymentIntegrationType
      businessPages: $businessPages
      events: $events
      orderStatus: $orderStatus
      date: $date
      sort: $sort
    ) {
      results {
        ...order
      }
      totalCount
    }
  }
  ${OrderEntityFragment}
`;
